<template>
  <validation-provider
    :vid="vid"
    :name="name"
    :rules="isAssistence ? rules : {}"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      v-if="isAssistence"
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk: isAssistence && Object.keys(rules).includes('required'),
      }"
    >
      <b-input-group>
        <!-- <b-form-input v-if="controlType === 'text'"
            :id="`input-${id}`"
            :name="`input-${id}`"
            :type="type"
            v-model="inputVal"
            :state="getValidationState({ dirty, validated, valid, errors })"
            :aria-describedby="`input-${id}-live-feedback`"
            :autocomplete="type === 'password' ? 'on' : 'off'"
            @blur="onBlur"
            width="0"
          ></b-form-input> -->
        <label :state="getValidationState({ dirty, validated, valid, errors })">
          {{ content }}</label
        >
        <!-- <slot name="append"></slot> -->
        <b-input-group-append>
          <b-button type="button" variant="lisaweb" @click="openModal()"
            >Modifica</b-button
          >
        </b-input-group-append>
        <b-modal
          :id="`modal-${id}`"
          title="Assistenza"
          size="sl"
          ok-only
          ok-variant="lisaweb"
          scrollable
          @hide="hideModal()"
        >
          <pre>{{ inputVal }}</pre>
          <pre> {{ content }}</pre>
          <p>
            Deve mostrare un campo di ricerca specifico alla relazione, e
            aggiornare l'id della relazione
          </p>
        </b-modal>
      </b-input-group>
    </b-form-group>
    <b-form-group
      v-else
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
    >
      <b-form-input
        v-if="controlType === 'text'"
        :id="`input-${id}`"
        :name="`input-${id}`"
        :type="type"
        v-model="inputVal"
        disabled
        plaintext
        :aria-describedby="`input-${id}-live-feedback`"
      ></b-form-input>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: null,
    content: {
      type: Object,
      default() {
        return {};
      },
    },
    vid: String,
    name: String,
    label: String,
    type: {
      default: "text",
      type: String,
    },
    controlType: {
      default: "text",
      type: String,
    },
    isAssistence: {
      default: false,
      type: Boolean,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  // watch: {
  //     value() {
  //         this.$emit('input', this.value);
  //     }
  // },
  methods: {
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    onBlur(e) {
      // console.debug("input onBlur", e);
      this.$emit("blur", e);
    },
    openModal() {
      // console.debug("openModal");
      this.$bvModal.show(`modal-${this.id}`);
    },
    hideModal() {
      console.debug("hideModal");
      // this.infoModal.title = "";
      // this.infoModal.content = "";
    },
  },
};
</script>
