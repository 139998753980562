<template>
  <div class="mb-3">
    <div v-for="(obj, index) in details" :key="'' + index + obj.id">
      <coass-detail
        v-if="inputVal.coass.coass_details[obj.id]"
        :name="noName ? '' : name + ' ' + (index + 1)"
        v-model="inputVal.coass.coass_details[obj.id]"
        :conf="obj"
        @select="onSelectCoassDetail"
        @unselect="onUnselectCoassDetail"
        @delete="onDeleteCoassDetail"
        :canDelete="canDelete"
        :disableAmounts="disableAmounts"
        :hideAmounts="hideAmounts"
        :disableCoass="disableCoass"
        :disableShare="disableShare"
        :rules="rules"
      ></coass-detail>
    </div>
    <!-- <b-row>
      <div class="col-md-6">
        <b>Totale: {{ toLocaleCurrency(total) }}</b>
      </div>
      <div class="col-md-4">
        <b v-if="enableRemainder">Residuo: {{ toLocaleCurrency(remainder) }}</b>
      </div>
    </b-row> -->
  </div>
</template>
<script>
import CoassDetail from "@/components/form/CoassDetail";
import { toLocaleCurrency } from "@/utils/strings";
import { computed } from "vue";

export default {
  props: {
    value: undefined,
    name: {
      type: String,
      default: "Delega",
    },
    details: {
      type: Array,
      default() {
        return [];
      },
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    disableAmounts: {
      type: Boolean,
      default: false,
    },
    hideAmounts: {
      type: Boolean,
      default: false,
    },
    disableCoass: {
      type: Boolean,
      default: false,
    },
    disableShare: {
      type: Boolean,
      default: false,
    },
    noName: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    // enableRemainder: {
    //   type: Boolean,
    //   default: false,
    // },
    // amountField: {
    //   type: String,
    //   default: "calculatedGross",
    // },
    // remainderFunction: null,
    repository: String,
  },
  provide() {
    // use function syntax so that we can access `this`
    return {
      canDelete: computed(() => this.canDelete),
      disableAmounts: computed(() => this.disableAmounts),
      hideAmounts: computed(() => this.hideAmounts),
      disableCoass: computed(() => this.disableCoass),
      disableShare: computed(() => this.disableShare),
    };
  },
  components: {
    CoassDetail,
  },
  data() {
    return {};
  },
  mounted() {},
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    total() {
      return Object.keys(this.inputVal.coass.coass_details)
        .map((key) => {
          return (
            +this.inputVal.coass.coass_details[key].net +
              this.inputVal.coass.coass_details[key].tax || 0
          );
        })
        .reduce(function(sum, i) {
          return sum + i;
        }, 0);
    },
    // amount() {
    //   switch (this.amountField) {
    //     case "calculatedGross":
    //       return (
    //         this.inputVal[this.repository].net +
    //         this.inputVal[this.repository].tax
    //       );
    //     case "gross":
    //       return this.inputVal[this.repository].gross;
    //     case "net":
    //       return this.inputVal[this.repository].net;
    //     case "byRemainderFunction":
    //       return this.remainderFunction();
    //   }
    //   return 0;
    // },
    // remainder() {
    //   return this.amount - this.total;
    // },
  },
  methods: {
    toLocaleCurrency,
    onSelectCoassDetail(selectedOption, id) {
      this.$emit("select", selectedOption, id);
    },
    onUnselectCoassDetail(removedOption, id) {
      this.$emit("unselect", removedOption, id);
    },
    onDeleteCoassDetail(id) {
      this.$emit("delete", id);
    },
  },
};
</script>
