import { render, staticRenderFns } from "./ShowCard.vue?vue&type=template&id=74a4518a&scoped=true&"
import script from "./ShowCard.vue?vue&type=script&lang=js&"
export * from "./ShowCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74a4518a",
  null
  
)

export default component.exports