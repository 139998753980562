<template>
  <b-modal
    id="quickSearchModal"
    size="lg"
    scrollable
    ok-only
    ok-title="Chiudi"
    ok-variant="lisaweb"
    header-bg-variant="lisaweb"
    no-close-on-esc
  >
    <!-- this modal does not have a close facility -->
    <!-- <template #modal-header="{ close }"> -->
    <!-- Emulate built in modal header close button action -->
    <!-- <b-button size="sm" variant="outline-danger" @click="close()">
        Close Modal
      </b-button> -->

    <template #modal-header="{ cancel }">
      <div slot="modal-title" class="modal-title">
        <b-icon icon="calendar2-event"></b-icon>
        <span>Cerca Anagrafica</span>
      </div>
      <button
        type="button"
        class="close"
        title="Chiudi"
        data-dismiss="modal"
        @click="cancel()"
      >
        &times;
      </button>
    </template>
    <!-- footer does not have default buttons -->
    <!-- <template #modal-footer="{ ok, cancel, hide }"> -->
    <template #modal-footer="{ cancel }">
      <b-form-group>
        <b-btn
          @click="
            cancel();
            clear();
          "
          size="sm"
          variant="outline-secondary"
          >Annulla</b-btn
        >
        <b-btn
          @click="
            cancel();
            setValue();
          "
          size="sm"
          variant="outline-lisaweb"
          >Seleziona</b-btn
        >
      </b-form-group>
    </template>

    <b-card>
      <b-card-text>
        <base-select
          vid="quick_search"
          name="quick_search"
          label="Codice Fiscale"
          v-model="inputVal"
          :options="ajaxOptions"
          @search-change="getAjaxOptions"
          :isLoading="isLoadingAjax"
          @select="onSelect"
          @remove="onRemove"
        />
        {{ surname }} {{ name }}
      </b-card-text>
    </b-card>
  </b-modal>
</template>
<script>
import BaseSelect from "@/components/form/BaseSelect";
// import BaseInput from "@/components/form/BaseInput";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data() {
    return {
      items: [],
      ajaxOptions: [],
      isLoadingAjax: false,
      name: null,
      surname: null,
    };
  },
  props: {
    value: null,
    search: {
      type: Object,
      default() {
        return {
          repository: "registry",
          helper: "byAttribute",
          field: "NINO",
          optionText: "attributables.NINO",
        };
      },
    },
  },
  components: {
    BaseSelect,
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    // ...mapGetters("auth", {
    //   getUsers: "users",
    // }),
  },
  methods: {
    getAjaxOptions(query) {
      const Repo = RepositoryFactory.get("registry");
      if (query.length > 2) {
        // let queryString = `perPage=0&byAttribute[NINO]=${query}`;
        let queryString = `perPage=0&${this.search.helper}[${this.search.field}]=${query}`;

        this.isLoadingAjax = true;

        Repo.index(queryString)
          .then((response) => {
            this.items = response.data.data;
            this.ajaxOptions = this.items.map((el) => {
              return {
                value: el.id,
                text: this.search.optionText
                  .split(".")
                  .reduce((a, b) => a[b], el),
              };
            });
            this.isLoadingAjax = false;
          })
          .catch((error) => {
            console.error(error);
            this.ajaxOptions = [];
          });
        // .finally(() => {
        //   this.isLoadingAjax = false;
        // });
      }
    },
    onSelect(selectedOption) {
      this.name = this.items.find(
        (el) => el.id === selectedOption
      ).attributables.NAME;
      this.surname = this.items.find(
        (el) => el.id === selectedOption
      ).attributables.SURN;
    },
    onRemove() {
      this.name = null;
      this.surname = null;
    },
    setValue() {
      this.$emit("set", this.inputVal);
    },
    clear() {
      this.inputVal = null;
      this.ajaxOptions = [];
      this.name = null;
      this.surname = null;
    },
  },
};
</script>
