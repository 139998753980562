<template>
  <validation-provider
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <!-- <b-form-datepicker
                :id="`input-${id}`"
                :name="`input-${id}`"
                :type="type"
                v-model="inputVal"
                :state="getValidationState({ dirty, validated, valid, errors })"
                :aria-describedby="`input-${id}-live-feedback`"
                locale="it"
                @input="onInput"
            ></b-form-datepicker> -->

      <b-input-group class="mb-3">
        <b-form-input
          :id="`input-${id}`"
          v-model="inputVal"
          type="text"
          :state="getValidationState({ dirty, validated, valid, errors })"
          :aria-describedby="`input-${id}-live-feedback`"
          placeholder="GG/MM/AAAA"
          @input="onTextInput"
          autocomplete="off"
          :title="
            inputVal
              .split('-')
              .reverse()
              .join('/')
          "
        ></b-form-input>
        <b-input-group-append>
          <b-form-datepicker
            :id="`datepicker-input-${id}`"
            :name="`input-${id}`"
            :type="type"
            v-model="inputVal"
            show-decade-nav
            locale="it"
            :date-format-options="{
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }"
            button-only
            button-variant="lisaweb"
            right
            @context="onContext"
            @input="onInput"
          ></b-form-datepicker>
        </b-input-group-append>
      </b-input-group>
      <!-- <p class="mb-1">Value: '{{ value }}'</p> -->
      <!-- <p class="mb-1">copyVal: '{{ copyVal }}'</p>
            <p class="mb-1">Selected: '{{ selected }}'</p>
            <p>Formatted: '{{ formatted }}'</p> -->

      <!-- <p>Value: '{{ inputVal }}'</p> -->
      <!-- :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" -->
      <!-- value-as-date -->
      <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
        errors[0]
      }}</b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: String,
    vid: String,
    name: String,
    label: String,
    type: {
      default: "text",
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      id: null,
      formatted: "",
      selected: "",
      copyVal: "",
    };
  },
  mounted() {
    this.id = this._uid;
    this.copyVal = this.value
      ? this.value
          .split("/")
          .reverse()
          .join("-")
      : "";
    // this.selected = this.value.split('/').reverse().join('-')
    // this.formatted = this.value.split('-').reverse().join('/')
    // this.inputVal = this.value
  },
  computed: {
    inputVal: {
      get() {
        return this.value ? this.copyVal : "";
      },
      set(val) {
        // console.debug("set: ", val);
        //   this.copyVal = val
        this.$emit("input", val);
      },
    },
  },
  // watch: {
  //     value() {
  //         this.$emit('input', this.value);
  //     }
  // },
  methods: {
    // getValidationState({ dirty, validated, valid = null }) {
    //     return dirty || validated ? valid : null;
    // },
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    // onBlur(e) {
    //     console.debug("input onBlur", e)
    //     this.$emit('blur', e)
    // },
    onInput(date) {
      // console.debug("input onInput",  date)
      if (date) this.copyVal = date; //.split('-').reverse().join('/')
      this.$emit("select", date);
    },
    onTextInput(date) {
      // console.debug("input onTextInput",  date)
      let v = date;
      if (v.match(/^\d{2}$/) !== null) {
        date = v + "/";
      } else if (v.match(/^\d{2}\/\d{2}$/) !== null) {
        date = v + "/";
      }
      this.copyVal = date;
      if (date.length === 10) {
        // this.selected = date.split('/').reverse().join('-')
        // this.formatted = date.split('/').reverse().join('-')
        this.copyVal = date
          .split("/")
          .reverse()
          .join("-");
        //this.inputVal = date.split('/').reverse().join('-')
        // this.copyVal = new Date(date.split('/').reverse().join('-'))
      }
    },
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
  },
};
</script>
