<template>
  <b-row>
    <div class="col-md-12" v-if="name">
      {{ name }}
    </div>
    <!-- seleziona coass -->
    <div class="col-md-2">
      <base-select
        :id="conf.id"
        :vid="'select' + conf.id"
        name="type"
        label="Compagnia Coass"
        v-model="inputVal.type"
        :options="conf.options"
        @select="onSelect"
        @remove="onRemove"
        :disabled="disableCoass"
      />
    </div>

    <!-- quota -->
    <div class="col-md-2">
      <base-currency
        :id="'quota-' + conf.id"
        :vid="'quota' + conf.id"
        name="quota"
        label="Quota"
        v-model="inputVal.share"
        :options="{ locale: 'it-IT', currencyDisplay: 'percent', precision: 2 }"
        placeholder="... quota"
        :readonly="!inputVal.type || disableShare"
        :rules="inputVal.type ? rules : {}"
      />
    </div>
    <!-- net -->
    <div class="col-md-2" v-if="!hideAmounts">
      <base-currency
        :id="'net-' + conf.id"
        :vid="'net' + conf.id"
        name="net"
        label="Netto"
        v-model="inputVal.net"
        :options="{ currency: 'EUR', locale: 'it-IT' }"
        placeholder="... netto"
        :readonly="!inputVal.type || disableAmounts"
        :rules="inputVal.type ? rules : {}"
      />
    </div>
    <!-- tax -->
    <div class="col-md-2" v-if="!hideAmounts">
      <base-currency
        :id="'tax-' + conf.id"
        :vid="'tax' + conf.id"
        name="tax"
        label="Tasse"
        v-model="inputVal.tax"
        :options="{ currency: 'EUR', locale: 'it-IT' }"
        placeholder="... tasse"
        :readonly="!inputVal.type || disableAmounts"
        :rules="inputVal.type ? rules : {}"
      />
    </div>
    <!-- totale -->
    <div class="col-md-2" v-if="!hideAmounts">
      <base-currency
        :id="'gross-' + conf.id"
        :vid="'gross' + conf.id"
        name="gross"
        label="Lordo"
        :value="inputVal.net + inputVal.tax"
        :options="{ currency: 'EUR', locale: 'it-IT' }"
        :readonly="true"
      />
    </div>
    <div
      class="form-group col-md-2 align-self-end"
      v-if="inputVal.type && canDelete"
    >
      <b-button
        size="sm"
        variant="lisaweb"
        class="mt-1 mr-1"
        title="Rimuovi"
        @click="onDelete(conf.id)"
      >
        <b-icon icon="trash"></b-icon>
      </b-button>
    </div>
  </b-row>
</template>
<script>
import BaseCurrency from "@/components/form/BaseCurrency";
import BaseSelect from "@/components/form/BaseSelect";
export default {
  inject: [
    "canDelete",
    "disableAmounts",
    "hideAmounts",
    "disableCoass",
    "disableShare",
  ],
  props: {
    value: undefined,
    name: String,
    conf: Object,
    // canDelete: {
    //   type: Boolean,
    //   default: false,
    // },
    // disableAmounts: {
    //   type: Boolean,
    //   default: false,
    // },
    // hideAmounts: {
    //   type: Boolean,
    //   default: false,
    // },
    // disableCoass: {
    //   type: Boolean,
    //   default: false,
    // },
    // disableShare: {
    //   type: Boolean,
    //   default: false,
    // },
    rules: Object,
  },
  components: {
    BaseCurrency,
    BaseSelect,
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {},
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    onSelect(selectedOption, id) {
      // console.debug("entryDetail select onSelect", selectedOption, id);
      this.$emit("select", selectedOption, id);
    },
    onRemove(removedOption, id) {
      // console.debug("entryDetail select onRemove", removedOption, id);
      this.$emit("unselect", removedOption, id);
    },
    onDelete(id) {
      this.$emit("delete", id);
    },
  },
};
</script>
