<template>
  <b-overlay center :show="isLoading" rounded="sm">
    <div class="container mt-5 my-5" v-if="!isLoading">
      <h5>Permessi POLCLIREL</h5>
      <div>
        PDF:
        {{ canReport("POLCLIRELLO", "pdf") }} CSV:
        {{ canReport("POLCLIREL", "csv") }}
      </div>
      <h5>use_fee: {{ $store.state.auth.use_fee }}</h5>
      <h5>lisacomunica</h5>
      <b-button variant="lisaweb" @click="sms_show()">SMS</b-button>
      <b-button variant="lisaweb" @click="sms_index()">SMS ALL</b-button>

      <h5>Genera CSV</h5>

      <b-button variant="lisaweb" @click="delete403()">Delete403</b-button>
      <b-button variant="lisaweb" @click="generateReport()"
        >Genera CSV</b-button
      >

      <div
        class="notes-counter"
        v-b-tooltip.hover.right
        v-b-tooltip.hover.html
        :title="notes_tooltip_content"
      >
        {{ notes_counter }}
      </div>

      <consolidation-calendar
        v-model="consDate"
        @input="printConsDate"
      ></consolidation-calendar>

      <!-- DYNAMICALLY ADD ELEMENTS -->
      <!-- <h5>Forme di pagamento: create</h5>
      <entry-details
        ref="edRef"
        :details="dtlscfg"
        v-model="form"
        :repository="repository"
        enableRemainder
        canDelete
        @select="
          selectCreateEntryDetail(form.book_entries.entry_details, ...arguments)
        "
        @unselect="
          unselectCreateEntryDetail(
            form.book_entries.entry_details,
            ...arguments
          )
        "
        @delete="
          deleteCreateEntryDetail(form.book_entries.entry_details, ...arguments)
        "
      ></entry-details>

      <h5>Forme di pagamento: edit</h5>
      <b-button
        type="button"
        variant="lisaweb"
        class="mb-2"
        @click="
          changeEntryDetails(form2.book_entries.entry_details);
          editEntryDetailsCompToggle
            ? (enableEditEntryDetailsComp = true)
            : (enableEditEntryDetailsComp = false);
        "
        :pressed.sync="editEntryDetailsCompToggle"
        >{{ editEntryDetailsCompToggle ? "Annulla" : "Modifica" }}
      </b-button>
      <b-button
        v-show="enableEditEntryDetailsComp"
        type="button"
        variant="lisaweb"
        size="sm"
        class="mb-2"
        @click="submitTreasuries"
        >Salva
      </b-button>

      <table v-if="!enableEditEntryDetailsComp">
        <thead>
          <tr>
            <th>Tipo Cassa</th>
            <th>Importo</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(detail, index) in entry_details" :key="index">
            <td>{{ detail.treasury.code }}</td>
            <td>{{ toLocaleCurrency(detail.gross) }}</td>
          </tr>
        </tbody>
      </table>
      <div v-if="enableEditEntryDetailsComp">
        <entry-details
          :details="dtlscfg"
          v-model="form2"
          noName
          canDelete
          @select="
            selectEditEntryDetail(
              form2.book_entries.entry_details,
              ...arguments
            )
          "
          @unselect="
            unselectEditEntryDetail(
              form2.book_entries.entry_details,
              ...arguments
            )
          "
          @delete="
            deleteEditEntryDetail(
              form2.book_entries.entry_details,
              ...arguments
            )
          "
        ></entry-details>
      </div> -->
      <b-card header="Compagnie Coass" header-tag="header" class="mt-1">
        <b-card-text>
          <!-- hideAmounts -->
          <!-- disableAmounts -->
          <coass-details
            ref="coassRef"
            :details="coassdtls"
            v-model="form"
            repository="insurer_participant"
            canDelete
            @select="
              selectCreateCoassDetail(form.coass.coass_details, ...arguments)
            "
            @unselect="
              unselectCreateCoassDetail(form.coass.coass_details, ...arguments)
            "
            @delete="
              deleteCreateCoassDetail(form.coass.coass_details, ...arguments)
            "
          ></coass-details>
        </b-card-text>
      </b-card>
      <hr />
      <!-- table with totals on footer and input text in cell -->
      <!-- :filterOn="{ byAttribute: { status_registry: 0 } }" -->
      <b-button
        type="button"
        variant="lisaweb"
        class="mb-2"
        @click="$refs[tableRef].fetch()"
        >Carica</b-button
      >

      <table-test
        :fields="fields"
        :repository="repository"
        :resource="resource"
        :filterName="filterName"
        :ref="tableRef"
        @rowSelector="aggiorna"
        @cellChange.once="includi"
        noActions
        includeRowSelector
      ></table-test>
      <b-button
        type="button"
        variant="lisaweb"
        class="mb-2"
        @click="aggiornaTutti"
        >Aggiorna Tutti</b-button
      >
      <!-- Input Group -->

      <div>
        <p>Anagrafica</p>
        <b-input-group size="sm" class="mt-0">
          <base-input v-model="inpt_label" />
          <b-input-group-append>
            <b-button
              size="sm"
              text="Button"
              variant="lisaweb"
              @click="openQuickSearch"
              >Ricerca Veloce</b-button
            >
          </b-input-group-append>
        </b-input-group>
      </div>
      <p>Label: {{ inpt_label }}</p>
      <p>V-Model: {{ form.quick_value }}</p>
      <div v-if="insurance_policy_data" class="insurance_policy_id">
        <span class="info">
          {{ "Numero Polizza: " + insurance_policy_data.number }}
          <br />
          {{ "Titolo polizza: " + insurance_policy_data.title }}
          <br />
          {{ "Codice Mandato: " + insurance_policy_data.mandate_code }}
          <br />
          Anagrafica:
          {{
            insurance_policy_data.registry.status_registry.value === 0
              ? insurance_policy_data.registry.attributables.NAME +
                " " +
                insurance_policy_data.registry.attributables.SURN
              : insurance_policy_data.registry.attributables.CNAM
          }}
        </span>
      </div>
      <!-- Input Group -->

      <quick-search-modal
        ref="quickSearch"
        v-model="form.quick"
        @set="setValue"
        @input="handleModalInput"
      ></quick-search-modal>

      <quick-search-policy-modal
        ref="quickSearchPolicy"
        @input="handlePolicyModalInput"
      ></quick-search-policy-modal>
      <!-- :search="{
          repository: 'registry',
          helper: 'byAttribute',
          field: 'NINO',
          optionText: 'attributables.NINO'
        }" -->

      <base-input name="Quick" label="Quick" />

      <b-button @click="openQuickSearch"> Quick Search </b-button>
      <b-button @click="openQuickSearchPolicy"> Quick Search Table</b-button>

      <b-button type="button" variant="lisaweb" @click="massDestroy"
        >Test Tasks Mass Destroy</b-button
      >
      <!-- <b-row>
        <b-button
          type="button"
          variant="lisaweb"
          @click="
            $showSnackbar({
              text: 'sono una snackbar di che non si chiude automaticamente',
              // pos: 'bottom-center',
              duration: 0,
              actionText: 'Forza chiudi',
              // onActionClick: null,
              textColor: 'cyan',
              actionTextColor: 'pink',
            })
          "
          >Snack Message Custom</b-button
        >

        <b-button
          type="button"
          variant="lisaweb"
          @click="
            $showSnackbar({
              preset: 'success',
              text: 'sono una snackbar di successo',
              // pos: 'bottom-center',
              actionText: 'Bella lì',
              actionTextColor: '#FFFFFF',
              onActionClick: yesCallback,
            })
          "
          >Snack Message Preset success</b-button
        >

        <b-button
          type="button"
          variant="lisaweb"
          @click="
            $showSnackbar({
              preset: 'error',
              text: 'sono una snackbar di errore',
              // pos: 'bottom-center',
              actionText: 'Report error',
              actionTextColor: '#FFFFFF',
              onActionClick: reportError,
              onClose: null,
            })
          "
          >Snack Message Preset error
        </b-button>

        <b-button
          type="button"
          variant="lisaweb"
          @click="
            $showSnackbar({
              preset: 'info',
              text: 'sono una snackbar di info senza action',
              // pos: 'bottom-center',
              showAction: false,
              actionText: 'Thanks',
              actionTextColor: '#FFFFFF',
            })
          "
          >Snack Message Preset info</b-button
        >
      </b-row> -->

      <!-- <b-button type="button" variant="lisaweb" @click="createTask(task_type)"
        >Crea Task</b-button
      > -->

      <!-- <event-create-modal
        v-model="selectedEvent"
        @cancel="cancelEventCreation"
        @create="createEvent"
        :fromCalendar="false"
      ></event-create-modal> -->

      <!-- <div class="">
        <b-tabs content-class="pt-1">
          <b-tab
            v-for="(obj, index) in Array.from(
              { length: 30 },
              (v, k) => k + 1
            ).map((e) => {
              return { value: `${e}`, name: 'Tab Numero ' + e };
            })"
            :key="index"
            :title="obj.name"
            :active="!index"
            ><p>I'm the {{ obj.value }} tab</p></b-tab
          >
        </b-tabs>
      </div> -->

      <h5>Registry SHOW</h5>

      <!-- <base-select
        name="Registry ID"
        label="Registry ID"
        v-model="resourceId"
        :options="
          Array.from({ length: 10 }, (v, k) => k + 1).map((e) => {
            return { value: e, text: e };
          })
        "
        @select="onSelect"
      /> -->

      <!-- <show-card
        v-for="(item, index) in detailFields"
        :key="index"
        :header="item.header"
        :fields="item.fields"
        :data="details"
        :labels="labels"
      >
      </show-card> -->

      <hr />

      <ValidationObserver ref="observer1" v-slot="{ invalid, handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit1)" ref="form1">
          <test-input
            name="attribute_NINO_value"
            vid="attribute_NINO_value"
            label="attribute_NINO_value (backend validation)"
            v-model="form.registry.attribute_NINO_value"
            @blur="apiValidate('registry', 'attribute_NINO_value', 'observer1')"
          />

          <button type="submit" :disabled="invalid">Submit</button>
          <button type="button" @click="testValidate('test1')">Backend</button>
          <button type="button" @click="reset('form1', 'observer1')">
            Reset
          </button>
        </form>
      </ValidationObserver>

      <h5>REPORT</h5>
      <!-- request_input -->
      <!-- prima i campi standard - di 1° livello -->
      <!-- poi i campi raggruppati in request_input -->
      <!-- <ValidationObserver ref="observer3" v-slot="{ invalid, handleSubmit }">
        <form @submit.prevent="handleSubmit(onSubmit3)" ref="form3">
          <group-inputs
            :inputs="
              Object.keys(beForm['report']).filter((e) => e !== 'request_input')
                .length
                ? Object.keys(beForm['report']).filter(
                    (e) => e !== 'request_input'
                  )
                : []
            "
            v-model="form['report']"
            :beForm="beForm['report']"
            :beRules="beRules['report']"
          ></group-inputs>
          <group-inputs
            groupingField="request_input"
            :inputs="
              Object.keys(beForm['report']['request_input']).length
                ? Object.keys(beForm['report']['request_input'])
                : []
            "
            v-model="form['report']['request_input']"
            :beForm="beForm['report']['request_input']"
            :beRules="beRules['report']['request_input']"
          ></group-inputs>

          <button type="submit" :disabled="invalid">Submit</button>
        </form>
      </ValidationObserver> -->

      <!-- https://www.smashingmagazine.com/2019/07/using-slots-vue-js/ -->
      <!-- https://stackoverflow.com/questions/47311936/v-model-and-child-components -->

      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.prevent="handleSubmit(onSubmit2)" ref="form">
          <base-select
            name="Nazione"
            vid="country"
            label="Nazione"
            v-model="country"
            :options="optCountries"
            :rules="{ required: true }"
            @select="onSelectCountry"
          />
          <base-select
            name="Provincia"
            vid="province"
            label="Provincia"
            v-model="province"
            :options="optProvinces"
            :rules="{ required: true }"
          />
          <base-currency
            vid="Amount"
            name="Amount"
            label="Euro Amount"
            v-model="form.amount"
            :options="{
              locale: 'it-IT',
              currency: 'EUR',
            }"
            :rules="getRules('Amount')"
          />

          <p>Price (in parent component): {{ form.amount }}</p>

          <base-input
            vid="attributables_NAME"
            name="attributables_NAME"
            label="Nome"
            v-model="form.name"
            :rules="{ required_with: '@attributables_SURN' }"
            textCase="upper"
            :readonly="true"
          />
          <base-input
            vid="attributables_SURN"
            name="attributables_SURN"
            label="Cognome"
            textcase="upper"
            v-model="form.surname"
          />
          <!-- :rules="{ required_with: '@attributables_NAME' }" -->
          <base-input
            vid="CELL"
            name="CELL"
            label="Cellulare"
            v-model="form.cell"
            type="tel"
          />

          <base-currency
            vid="decimal"
            name="Decimal"
            label="Decimal"
            v-model="form.decimal"
            :options="{
              locale: 'it-IT',
              currency: 'EUR',
              currencyDisplay: 'hidden',
              precision: 2,
            }"
            :rules="getRules('Decimal')"
          />

          <base-select
            name="food"
            vid="food"
            label="Cibo"
            v-model="form.food"
            :options="foods"
            :rules="{ required: true }"
            disabled
          />

          <base-select
            name="beers"
            vid="beers"
            label="Birre"
            v-model="form.beers"
            :options="beers"
            :multiple="true"
            :taggable="false"
            :closeOnSelect="false"
            @input="onInputBeers"
            @select="onSelectBeers"
            @remove="onRemoveBeers"
            :rules="{ required_if: { target: 'food', values: ['apple'] } }"
          >
          </base-select>

          <base-select
            name="Tags"
            vid="tags"
            label="Tagghi"
            v-model="form.tag"
            :options="tags"
            :rules="{ required: true }"
            :taggable="true"
            :multiple="true"
            :closeOnSelect="false"
          />

          <!-- per il momento non si riesce ad integrare il raggruppamento in BaseSelect -->
          <!-- <base-select
            name="Grouped"
            vid="grouped"
            label="Raggruppate"
            v-model="form.grouped"
            :options="grouped_options"
            groupvalues="libs"
            grouplabel="language"
            :groupselect="false"
          /> -->
          <!-- <multiselect
            v-model="form.grouped"
            :options="grouped_options"
            group-values="libs"
            group-label="language"
            :group-select="false"
            track-by="text"
            label="text"
          /> -->

          <base-datepicker
            vid="Date"
            name="Una data"
            label="Una data"
            v-model="form.aDate"
          />
          DATA: {{ form.aDate }}
          <!-- :rules="getRules('aDate')" -->
          <button @click="$set(form, 'aDate', '1900-01-31')">Proceed</button>
          <button @click="form.aDate = null">Clear Only</button>
          <button
            @click="
              form.aDate = null;
              $set(form, 'aDate', savedDate);
            "
          >
            Clear and Set
          </button>

          <base-datepicker
            vid="Another"
            name="Altra data"
            label="Altra data"
            v-model="form.anotherDate"
          />
          <!-- :rules="getRules('anotherDate')" -->

          <test-datepicker
            vid="born_test_date"
            name="Test Data"
            label="Nato il (test datepicker)"
            v-model="form.aTestDate"
            :rules="{ required: true }"
          />

          <base-timepicker
            vid="born_time"
            name="Orario"
            label="Nato alle (test timepicker)"
            v-model="form.aTestTime"
            :rules="{ required: true }"
          />

          <!-- <base-month-picker
            vid="month"
            name="Mese"
            label="Scegli il mese"
            v-model="form.month"
            :defaultMonth="form.month.monthIndex"
            :defaultYear="form.month.year"
            clearable
          /> -->
          <base-month-picker
            vid="month"
            name="Mese"
            label="Scegli il periodo"
            v-model="form.period"
            :clearable="true"
          />
          <!-- <p>{{ form.month ? form.month.monthIndex : "N/A"}}/{{ form.month ? form.month.year : "N/A"}}</p> -->
          <p>{{ form.month }}</p>

          <base-checkbox
            name="Agree"
            label="Agree the terms"
            v-model="form.agree"
            :rules="{ required: true }"
            chk_val="1"
            unchk_val="0"
            :disabled="false"
          />
          <!-- chk_val=1
            unchk_val=0 -->

          <advanced-checkbox
            name="Social"
            label="I miei social"
            v-model="form.multiple"
            :options="multiple"
            :rules="{ required: true }"
          />

          <advanced-checkbox
            name="Bands"
            label="Big 4"
            v-model="form.bands"
            :options="bands"
            :rules="{ required: true }"
          />

          <base-radio
            name="Radios"
            vid="radios"
            label="Choose your radio"
            v-model="form.radio"
            :options="radios"
            :rules="{ required: true }"
          />

          <base-radio
            name="Radios2"
            vid="radios2"
            label="Choose your radio 2"
            v-model="form.radio2"
            :options="radios2"
            :rules="{ required: true }"
          />

          <base-textarea
            name="textArea"
            vid="textArea"
            label="Una Text Area"
            v-model="form.textarea"
            rows="4"
            maxrows="4"
          />

          <b-button type="submit" @click="onSubmit2()" variant="lisaweb"
            >Submit</b-button
          >
          <b-button class="ml-2" @click="resetForm()">Reset</b-button>
          <!-- <b-button
            type="button"
            variant="outline-lisaweb"
            @click="reset('form', 'observer')"
            >Reset</b-button
          > -->
        </b-form>
      </validation-observer>

      <!-- <prova></prova> -->
      <hr />
      <!-- <sample-table></sample-table> -->

      <!-- <div>
        <b-button v-b-modal.modalRegistryDetails>Show details</b-button>

        <b-modal
          id="modalRegistryDetails"
          :title="selected.text"
          size="xl"
          scrollable
          ok-only
          ok-variant="lisaweb"
          header-bg-variant="lisaweb"
        >
          <div>
            <b-tabs content-class="pt-1" lazy>
              <b-tab
                v-for="(tab, index) in tabs"
                :key="index"
                @click="selected = tab"
                :title="tab.text"
                :active="index === 0"
              >
                <keep-alive>
                  <component
                    :is="selected.name"
                    :resourceId="resourceId"
                  ></component>
                </keep-alive>
              </b-tab>
            </b-tabs>
          </div>
        </b-modal>
      </div> -->

      <hr />
      <!-- append a button specific for this input -->
      <base-dynamic-input
        name="baseDynamicInputManual"
        :isAssistence="isAssistence ? true : false"
        v-model="baseDynamicInputManual"
        type="text"
        controlType="text"
        label="Base Dynamic Input (manual control)"
        aria-placeholder=""
        :rules="{ required: true }"
        textcase="upper"
      />

      <advanced-dynamic-input
        name="advancedDynamicInputManual"
        :isAssistence="isAssistence ? true : false"
        v-model="relationData.id"
        :content="{
          name: relationData.name,
          addr: relationData.addr,
          cf: relationData.cf,
          ispl: relationData.ispl,
          ispl_date: relationData.ispl_date,
        }"
        type="text"
        controlType="text"
        label="Advanced Dynamic Input (manual control)"
        aria-placeholder=""
        :rules="{ required: true }"
      />

      <base-radio
        name="Poteri"
        label="Choose your powers!"
        v-model="isAssistence"
        :options="[
          { value: 0, text: 'Agenzia' },
          { value: 1, text: 'Assistenza' },
        ]"
      />
      <hr />

      <base-dynamic-input
        name="baseDynamicInputAcl"
        :isAssistence="roles.includes('admin-assistenza')"
        v-model="baseDynamicInputAcl"
        type="text"
        controlType="text"
        label="Base Dynamic Input (acl control)"
        aria-placeholder=""
        :rules="{ required: true }"
      />

      <advanced-dynamic-input
        name="advancedDynamicInputAcl"
        :isAssistence="roles.includes('admin-assistenza')"
        v-model="advancedDynamicInputAcl"
        type="text"
        controlType="text"
        label="Advanced Dynamic Input (acl control)"
        aria-placeholder=""
        :rules="{ required: true }"
      />

      <hr />

      <!-- <b-card
        header="Dati identificativi"
        header-tag="header"
      >
          <b-row v-for="(item, index) in items" :key="index">
            <div class="col-md-4 border-top">
              {{ item.label }}
            </div>
            <div class="col-md-8 border-top border-left">
              {{ item.text }}
            </div>
          </b-row>
      </b-card> -->

      <!-- <b-card v-for="(item, index) in detailFields" :key="index"
        :header="item.header"
        header-tag="header"
      >
          <b-row v-for="(field, index2) in item.fields" :key="index2">
            <div class="col-md-4 border-top">
              {{ labels[field.label] }}
            </div>
            <div class="col-md-8 border-top border-left">
              {{ texts(field.value) }}
            </div>
          </b-row>
      </b-card> -->
      <b-button
        type="button"
        variant="lisaweb"
        class="mb-2"
        @click="errorTest()"
        >Error test a</b-button
      >
      <!-- NEW: openModal from button -->
      <b-button
        type="button"
        variant="lisaweb"
        class="mb-2"
        @click="openModal(1, 0, $event.target)"
        >Apri General</b-button
      >
      <b-modal
        :id="infomodalName"
        :title="modalTitle"
        size="xl"
        ok-only
        ok-variant="lisaweb"
        header-bg-variant="lisaweb"
        no-close-on-backdrop
      >
        <component :is="selected" :resourceId="modalResourceId"></component>
      </b-modal>
    </div>
    <!-- <div v-else class="d-flex justify-content-center mb-3">
      <b-spinner label="Loading..." variant="warning"></b-spinner>
    </div> -->
    <template #overlay>
      <div class="text-center">
        <base-icon name="loading" width="35" height="35" />
        <p id="cancel-label">Operazione in corso...</p>
      </div>
    </template>
  </b-overlay>
</template>

<script>
import moment from "moment";
moment.locale("it");
// // import { showSnackbar } from "@/utils/messages";
import BaseIcon from "@/components/BaseIcon";
import ConsolidationCalendar from "@/components/ConsolidationCalendar";
import TableTest from "@/components/tables/Test";
// import EntryDetails from "@/components/form/EntryDetails";
import CoassDetails from "@/components/form/CoassDetails";
import BaseInput from "@/components/form/BaseInput";
import TestInput from "@/components/form/TestInput";
import BaseDynamicInput from "@/components/form/BaseDynamicInput";
import AdvancedDynamicInput from "@/components/form/AdvancedDynamicInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseRadio from "@/components/form/BaseRadio";
// import BaseMultiSelect from "@/components/form/BaseMultiSelect";
// import BaseTaggableSelect from "@/components/form/BaseTaggableSelect";
import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import BaseMonthPicker from "@/components/form/BaseMonthPicker";
import BaseTimepicker from "@/components/form/BaseTimepicker";
import BaseCurrency from "@/components/form/BaseCurrency";
import TestDatepicker from "@/components/form/TestDatepicker";
import AdvancedCheckbox from "@/components/form/AdvancedCheckbox";
import ShowCard from "@/components/ShowCard";
import { mapGetters, mapActions } from "vuex";
import { prepareRules } from "@/utils/validation";
// import { getFormFieldsKeys } from "@/utils/forms";
// import { showSnackbar } from "@/utils/messages";
// import Prova from "@/components/Prova";

import General from "@/components/wallet/registries/details/General";
import GeneralRegistry from "@/components/wallet/registries/details/General";
import GeneralPolicy from "@/components/wallet/policies/details/General";
// import Insurances from "@/components/wallet/registries/details/Insurances";
import Takings from "@/components/wallet/registries/details/Takings";
// import Accident from "@/components/wallet/registries/details/Accident";
import Appointments from "@/components/wallet/registries/details/Appointments";
import Notes from "@/components/wallet/registries/details/Notes";
import Group from "@/components/wallet/registries/details/Group";
import Documents from "@/components/wallet/registries/details/Documents";
import Relations from "@/components/wallet/registries/details/Relations";
import Prints from "@/components/wallet/registries/details/Prints";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import ShowMixin from "@/mixins/ShowMixin";
import FormMixin from "@/mixins/FormMixin";
import ConfirmModalMixin from "@/mixins/ConfirmModalMixin";
// import EntryDetailsMixin from "@/mixins/EntryDetailsMixin";
import CoassDetailsMixin from "@/mixins/CoassDetailsMixin";
// import EventCreateModal from "@/components/modals/eventCreate";
import QuickSearchModal from "@/components/modals/quickSearch";
import QuickSearchPolicyModal from "@/components/modals/quickSearchPolicy";
import { toLocaleCurrency } from "@/utils/strings";
// import ed from "@/../tests/entry_details.json";
import { getTreasuryCodes } from "@/utils/accounting";
import GroupInputs from "@/components/form/GroupInputs";

export default {
  mixins: [
    ShowMixin,
    FormMixin,
    ConfirmModalMixin /* , EntryDetailsMixin */,
    CoassDetailsMixin,
  ],
  data() {
    return {
      modalResourceId: null,
      infomodalName: "modaleMix",
      modalTitle: null,
      selected: null,
      consDate: "2022-05-30",
      savedDate: "1971-02-21",
      notes: {
        anagrafica: [
          {
            title: "anagrafica 1",
            date: "2022-01-01",
          },
          {
            title: "anagrafica 2",
            date: "2022-01-02",
          },
        ],
        polizze: [
          {
            title: "polizze 1",
            date: "2022-02-01",
          },
        ],
      },
      breadcrumb: [],
      json_data: [
        { name: "John", surname: "Doe", age: 20, salary: 20.0, hours: 37.4 },
        { name: "John", surname: "Roe", age: 40, salary: 40.0, hours: 35.2 },
        { name: "Jane", surname: "Woe", age: 50, salary: 52.0, hours: 30.4 },
      ],
      id: null,
      // uid: null,
      confirm: "",
      // EDIT TREASURIES

      // DYNAMICALLY ADD ELEMENTS
      // treasury_options: [],
      // entry_details: [], // OLD keep data from db for edit details
      entryDetails: [], // NEW
      coassDetails: [],
      treasuryIndex: [
        {
          code: "AB",
          is_system: true,
          is_abstract: false,
        },
        {
          code: "AC",
          is_system: true,
          is_abstract: false,
        },
        {
          code: "SS",
          is_system: true,
          is_abstract: false,
        },
        {
          code: "EN",
          is_system: true,
          is_abstract: true,
        },
        {
          code: "PR",
          is_system: true,
          is_abstract: true,
        },
        {
          code: "CN",
          is_system: false,
          is_abstract: false,
        },
        {
          code: "AS",
          is_system: false,
          is_abstract: false,
        },
      ],
      foglioQuadrature: {},
      changedIds: [], // global list to track the changed table rows (basecurrency inputs changed)
      foods: [
        // { value: null, text: 'Choose...' },
        { value: "apple", text: "Apple" },
        { value: "orange", text: "Orange" },
      ],
      beers: [
        // { value: null, text: 'Choose...' },
        { value: "peroni", text: "Peroni" },
        { value: "becks", text: "Becks" },
        { value: "moretti", text: "Moretti" },
        { value: "ceres", text: "Ceres" },
        { value: "heineken", text: "Heineken" },
      ],
      tags: [
        { text: "Vue.js", value: "vu" },
        { text: "Javascript", value: "js" },
        { text: "Open Source", value: "os" },
      ],
      multiple: [
        { text: "Facebook", value: "1" },
        { text: "Instagram", value: "2" },
        { text: "LinkedIn", value: "3" },
        { text: "Twitter", value: "4" },
      ],
      bands: [
        { text: "Metallica", value: "1" },
        { text: "Megadeth", value: "2" },
        { text: "Anthrax", value: "3" },
        { text: "Slayer", value: "4" },
      ],
      radios: [
        { text: "Toggle this custom radio", value: "first" },
        { text: "Or toggle this other custom radio", value: "second" },
        { text: "This one is Disabled", value: "third", disabled: true },
        { text: "This is the 4th radio", value: { fourth: 4 } },
      ],
      radios2: [
        { text: "Toggle this custom radio 2", value: "first" },
        { text: "Or toggle this other custom radio 2", value: "second" },
        { text: "This one is Disabled 2", value: "third", disabled: true },
        { text: "This is the 4th radio 2", value: { fourth: 4 } },
      ],
      grouped_options: [
        {
          language: "Javascript",
          libs: [
            { text: "Vue.js", value: 1 },
            { text: "Adonis", value: 2 },
          ],
        },
        {
          language: "Ruby",
          libs: [
            { text: "Rails", value: 3 },
            { text: "Sinatra", value: 4 },
          ],
        },
        {
          language: "Other",
          libs: [
            { text: "Laravel", value: 5 },
            { text: "Phoenix", value: 6 },
          ],
        },
      ],
      inpt_label: "",
      insurance_policy_data: null,
      form2: {
        // for edit details
        book_entries: {
          // EDIT TREASURIES
          entry_details: {},
        },
      },
      form: {
        registry: {
          attribute_NINO_value: "Pazzo",
        },
        // for create details
        book_entries: {
          // DYNAMICALLY ADD ELEMENTS
          entry_details: {},
        },
        coass: {
          // DYNAMICALLY ADD ELEMENTS
          coass_details: {},
        },
        name: "Prova",
        surname: "",
        cell: "",
        quick: "",
        quick_inpt_label: "",
        quick_value: "",
        decimal: 10,
        food: null,
        beers: null,
        tag: null,
        // aDate: "1971-02-21",
        // aDate: "1971-02-21T00:00:00.000000Z",
        aDate: null,
        anotherDate: null,
        aTestDate: "01/05/2021",
        aTestTime: "09:30:00",
        // month: {
        //   monthIndex: null, // parseInt(moment().format("M")),
        //   year: null, // parseInt(moment().format("YYYY"))
        // },
        period: moment(),
        multiple: ["2", "3"],
        bands: ["1", "4"],
        radio: "first",
        radio2: "second",
        textarea: null,
        grouped: null,
        agree: null,
        // prova: null,
        test: null,
        test1: null,
        amount: 0,
      },
      tabs: [
        { name: "General", text: "Generale" },
        // { name: "Insurances", text: "Polizze" },
        { name: "Takings", text: "Incassi" },
        // { name: "Accident", text: "Sinistri" },
        { name: "Appointments", text: "Appuntameni" },
        { name: "Notes", text: "Note" },
        { name: "Group", text: "Gruppo" },
        { name: "Documents", text: "Doc" },
        { name: "Relations", text: "Relazioni" },
        { name: "Prints", text: "Stampe" },
      ],
      // selected: "General",
      // selected: { name: "General", text: "Generale" },
      advancedDynamicInputManual:
        "PETROLATI SILVIA\
        VIA BIDONI, 80\
        19020, Merlara (PD)\
        GRSMSS23M55L819A\
        VEZANO LIGURE 15/08/1923",
      advancedDynamicInputAcl:
        "PETROLATI SILVIA\
        VIA BIDONI, 80\
        19020, Merlara (PD)\
        GRSMSS23M55L819A\
        VEZANO LIGURE 15/08/1923",
      relationData: {
        id: 123,
        name: "PETROLATI SILVIA",
        addr: "VIA BIDONI, 80 - 19020, Merlara (PD)",
        cf: "GRSMSS23M55L819A",
        ispl: "VEZANO LIGURE",
        ispl_date: "15/08/1923",
      },
      baseDynamicInputManual: "default value",
      baseDynamicInputAcl: "default value",
      isAssistence: 0,
      // variables for "show" registry
      isLoading: false,
      repository: "registry",
      resource: "registries",
      filterName: "prova",
      resourceId: 1,
      defaultLabels: {
        NAME: "Sappelle (da defaultLabels)",
        DOB: "Data di nascita (da defaultLabels)",
        COUNCIL: "Città (da defaultLabels)", // in beForm non c'è
        CAP: "C.A.P. (da defaultLabels)", // in beForm non c'è
        PROVINCE: "Provincia (da defaultLabels)", // in beForm non c'è
      },
      details: [],
      detailFields: {
        identificativi: {
          header: "Dati Identificativi",
          fields: [
            {
              label: "NAME",
              value: "attributables.NAME",
            },
            {
              label: "SURN",
              value: "attributables.SURN",
            },
            {
              label: "NINO",
              value: "attributables.NINO",
            },
            {
              label: "PROF",
              value: "attributables.PROF",
            },
          ],
        },
        contatti: {
          header: "Contatti",
          fields: [
            {
              label: "CELL",
              value: "attributables.CELL",
            },
            {
              label: "MAIL",
              value: "attributables.MAIL",
            },
          ],
        },
        nascita: {
          header: "Nato a:",
          fields: [
            {
              label: "POB",
              value: "attributables.POB.COUNCIL",
            },
            {
              label: "DOB",
              value: "attributables.DOB",
            },
            {
              label: "CAP", // in beForm non c'è
              value: "attributables.POB.CAP",
            },
          ],
        },
      },
      defaultCountryName: "Italia",
      country: null,
      province: null,
      optCountries: [],
      optProvinces: [],
      selectedEvent: this.initEvent(),
      tableRef: "TestTableRef",
      fields: [
        // {
        //   key: "colored",
        //   label: "",
        //   sortable: false,
        //   thStyle: {
        //     width: "4px",
        //   },
        //   // tdClass: "red",
        // },
        {
          key: "selezionato",
          label: "Seleziona",
          sortable: false,
        },
        {
          key: "attributables.SURN",
          label: "Cognome",
          stickyColumn: true,
          sortable: false,
        },
        {
          key: "status_registry.text",
          label: "Tipo",
          sortable: false,
          class: "text-center",
        },
        {
          key: "insurer_id",
          label: "ID Compagnia",
          formatter: (value) => toLocaleCurrency(value),
          tdClass: "text-right",
        },
        {
          key: "rowSelector",
          label: "Azioni",
          // thClass: "w-25",
        },
      ],
    };
  },
  components: {
    BaseIcon,
    GeneralRegistry,
    GeneralPolicy,
    ConsolidationCalendar,
    // EntryDetails,
    CoassDetails,
    TableTest,
    BaseInput,
    BaseTextarea,
    TestInput,
    BaseDynamicInput,
    AdvancedDynamicInput,
    BaseSelect,
    BaseRadio,
    BaseDatepicker,
    BaseMonthPicker,
    BaseTimepicker,
    BaseCurrency,
    TestDatepicker,
    // BaseMultiSelect,
    // BaseTaggableSelect,
    BaseCheckbox,
    AdvancedCheckbox,
    // Prova,
    General,
    // Insurances,
    Takings,
    // Accident,
    Appointments,
    Notes,
    Group,
    Documents,
    Relations,
    Prints,
    ShowCard,
    // EventCreateModal,
    QuickSearchModal,
    QuickSearchPolicyModal,
    GroupInputs,
  },
  methods: {
    toLocaleCurrency,
    openModal(id, index, button) {
      // // SHORTCUT (Calendar)
      // console.log(this.$route);
      // if (this.$route.params.data) {
      //   item = this.$route.params.data;
      //   this.setDetailTitle(item);
      // }
      // if (!(Object.keys(item).length === 1 && Object.keys(item)[0] === "id")) {
      //   this.setDetailTitle(item);
      // }
      this.modalResourceId = id;
      this.selected = "GeneralPolicy";
      this.modalTitle = "Polizze";

      this.$bvModal.show(this.infomodalName, button);
    },
    printConsDate(date) {
      console.log("emitted date: ", date);
      console.log("v-model this.consDate: ", this.consDate);
    },
    onSubmit2() {
      console.log("FORM: ", this.form);
      // let arr = [];
      // console.log("FORM: ", this.$refs);
      //let multiselectDefaultValue = "Seleziona";
      // for (let i = 0; i <= this.$refs.form.length - 1; i++) {
      //   if (
      //     this.$refs.form[i].className === "multiselect__input" &&
      //     this.$refs.form[i]["offsetParent"] && //this.$refs.form[i].offsetParent.innerText !== multiselectDefaultValue
      //     this.$refs.form[i].parentElement.offsetParent.__vue__.value
      //   ) {
      //     console.log("multiselect!");

      //     let multiselectLabel = this.$refs.form[
      //       i
      //     ].offsetParent.offsetParent.innerText
      //       .replace(this.$refs.form[i].offsetParent.innerText, "")
      //       .trim();
      //     //let multiselectText = this.$refs.form[i].parentElement.innerText.replace("\n", ",")
      //     let multiselectOptionKeys = this.$refs.form[i].parentElement
      //       .offsetParent.__vue__.optionKeys;
      //     let multiselectValues = this.$refs.form[i].parentElement.offsetParent
      //       .__vue__.value;
      //     let multiselectOptions = this.$refs.form[i].parentElement.offsetParent
      //       .__vue__.options;
      //     console.log("multiselectValues: ", multiselectValues);
      //     console.log("multiselectOptionKeys: ", multiselectOptionKeys);
      //     console.log("multiselectOptions: ", multiselectOptions);
      //     let multiselectIds = [];
      //     multiselectValues = Array.isArray(multiselectValues)
      //       ? multiselectValues
      //       : [multiselectValues];
      //     for (let i = 0; i <= multiselectOptions.length; i++) {
      //       if (multiselectValues.includes(multiselectOptions[i])) {
      //         multiselectIds.push(i);
      //       }
      //     }
      //     console.log("multiselectIds: ", multiselectIds);
      //     let multiselectText = [];
      //     for (let i = 0; i <= multiselectOptionKeys.length; i++) {
      //       if (multiselectIds.includes(i)) {
      //         multiselectText.push(multiselectOptionKeys[i]);
      //       }
      //     }
      //     arr.push({ label: multiselectLabel, value: multiselectText });
      //   } else if (
      //     this.$refs.form[i]["value"] &&
      //     this.$refs.form[i]["className"].includes("form-control")
      //   ) {
      //     arr.push({
      //       label: this.$refs.form[i].labels[0].innerText,
      //       value: this.$refs.form[i].value,
      //     });
      //   } else {
      //     if (this.$refs.form[i]["checked"]) {
      //       console.log("check", this.$refs.form[i]["checked"]);
      //       arr.push({
      //         label: this.$refs.form[i].labels[0].innerText,
      //         value: this.$refs.form[i].value,
      //       });
      //     }
      //   }
      // }
      // this.breadcrumb = arr;
    },
    onSubmit3() {
      this.$showSnackbar({
        preset: "success",
        text: JSON.stringify(this.form["report"]),
      });
    },
    generateReport() {
      const data = this.json_data.map((row) => ({
        name: row.name,
        age: row.age,
      }));
      const csvData = this.objecToCSV(data);
      //Download
      const blob = new Blob([csvData], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("hidden", "");
      a.setAttribute("href", url);
      a.setAttribute("download", "download.csv");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      console.log("Data: ", data);
    },
    objecToCSV(data) {
      const csvRows = [];
      //Headers
      const headers = Object.keys(data[0]);
      csvRows.push(headers.join(","));
      //Rows
      for (const row of data) {
        const values = headers.map((header) => {
          const escaped = ("" + row[header]).replace(/"/g, '\\"');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(","));
      }
      return csvRows.join("\n");
    },
    range(start, end, step = 1) {
      let output = [];
      if (typeof end === "undefined") {
        end = start;
        start = 0;
      }
      for (let i = start; i < end; i += step) {
        output.push(i);
      }
      return output;
    },
    errorTest() {
      let id = 2500;
      this.delete("claim", id)
        .then(() => {
          this.$showSnackbar({
            preset: "success",
            text: `Azione Completata: Indirizzo Eliminato`,
          });
          this.$refs[this.tableRef].fetch();
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    // numberFormat(value) {
    //     this.form.decimal = Number(value.replace(/\./, ''))
    //     return value == '0' ? '' : this.form.decimal.toLocaleString();
    // },
    numberFormat(value) {
      if (this.type === "number") {
        this.decimal = parseFloat(value.replace(/,/, ".")).toLocaleString(
          "it-IT"
        );
      }
      // return value
    },
    getTreasuryCodes,
    // toCurrency(value) {
    //   if (!value) value = "0.00001"; // trick to allow display "0.00 €"
    //   return toLocaleCurrency(parseFloat(value));
    // },
    aggiorna(row) {
      console.log("mo' chiamo 'o bechend");
      console.log(JSON.stringify(row));
      const id = row.id;
      const body = {
        "attributables.SURN": row.attributables.SURN,
        insurer_id: row.insurer_id,
      };
      console.log("updating...", id);
      console.log("payload", body);
      // remove from global list
      let index = this.changedIds.indexOf(id);
      if (index !== -1) {
        this.changedIds.splice(index, 1);
      }
      console.log("this.changedIds: ", this.changedIds);
    },
    includi(id) {
      console.log("mo includo 'sto id: ", id);
      // add to global list
      if (this.changedIds.indexOf(id) === -1) {
        this.changedIds.push(id);
      }
      console.log("this.changedIds: ", this.changedIds);
    },
    aggiornaTutti() {
      // filtra le righe da aggiornare tenendo solo quelle in this.changedIds
      let rows = this.$refs[this.tableRef].items.filter((item) =>
        this.changedIds.includes(item.id)
      );
      console.log("aggiornaTutti: ", rows);
      // TODO: loop rows, prepare a body for the update API
      for (let index = 0; index < rows.length; index++) {
        const row = rows[index];
        const id = row.id;
        const body = {
          "attributables.SURN": row.attributables.SURN,
          insurer_id: row.insurer_id,
        };
        console.log("updating...", id);
        console.log("payload", body);
      }

      this.changedIds = [];
    },
    ...mapActions("queue", ["notifyQueue"]),
    ...mapGetters("auth", {
      countries: "countries",
      provinces: "provinces",
      getTreasuries: "treasuries",
      getInsurers: "insurers",
      getReport: "report",
    }),
    massDestroy() {
      // const Repo = RepositoryFactory.get('task');

      this.$showSnackbar({
        preset: "error",
        text: "Non implementato",
        // pos: 'bottom-center',
        actionText: "[ :-( ]",
      });
    },
    //Handle modal $emit
    handleModalInput(value) {
      console.log("HandleModalInput", value);
      console.log("Refs: ", this.$refs);
      this.inpt_label = this.$refs.quickSearch.ajaxOptions[0]
        ? this.$refs.quickSearch.ajaxOptions[0].text
        : "";
      this.form.quick_value = value;
    },
    handlePolicyModalInput(value) {
      console.log("handlePolicyModalInput", value);
      console.log("Refs: ", this.$refs);
      this.form.quick_value = value.id; // the form v-model
      this.insurance_policy_data = value; // the infos
      this.$bvModal.hide("quickSearchPolicyModal");
    },
    openQuickSearch() {
      this.form.quick = null;
      this.$refs.quickSearch.ajaxOptions = [];
      this.$bvModal.show("quickSearchModal");
    },
    openQuickSearchPolicy() {
      this.form.quick_value = null; // the form v-model
      this.$bvModal.show("quickSearchPolicyModal");
    },
    setValue(val) {
      console.log("setValue - modal: ", val);
    },
    onInputBeers(selected) {
      console.log("onInputBeers: ", selected);
    },
    onSelectBeers(selected) {
      console.log("onSelectBeers: ", selected);
    },
    onRemoveBeers(selected) {
      console.log("onRemoveBeers: ", selected);
    },
    onSelectCountry(selected) {
      this.province = null;
      this.optProvinces = this.provinces()(selected);
    },
    // addTag(newTag) {
    //   const tag = {
    //     text: newTag,
    //     value: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000),
    //   };
    //   this.options.push(tag);
    //   this.form.tag.push(tag);
    // },
    // getValidationState({ dirty, validated, valid = null }) {
    //   return dirty || validated ? valid : null;
    // },
    async test() {
      console.debug(await this.$refs.observer1.validate());
    },
    getRules(field) {
      // console.debug("getRules:", field)
      // get permissions from store: this.permissions
      const permRules = {
        Name: { rules: ["required", "min: 3"] },
        aDate: { rules: ["after_or_equal: Another"] },
        anotherDate: { rules: [] },
        Decimal: { rules: ["not_in: 0,1"] },
        Amount: { rules: ["not_in: 0,1"] },
        test1: { rules: ["required"] },
      };
      // //   values = arr[1].split(":")
      let rules = prepareRules(permRules[field].rules);
      // console.log(`rules: ${JSON.stringify(rules)}`);
      //   //{ required: true, min: 3 }
      return rules;
    },
    testValidate(field) {
      // DEBUG: simulate
      console.debug(">>> testValidate", field);
      const errors = {};
      // errors[field] = [`Field ${field} is forbidden!`];
      // console.debug(JSON.stringify(errors));
      // this.$refs.observer1.setErrors(errors);

      this.validate("registry", "attribute_NINO_value")
        .then(() => {
          console.log("attribute_NINO_value is valid!");
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          errors[field] = errMsg.split("|");
          console.debug(JSON.stringify(errors));
          this.$refs.observer1.setErrors(errors);
        });
    },
    reset(form, observer) {
      this.$refs[form].reset();
      this.$nextTick(() => {
        this.$refs[observer].reset();
      });
    },
    onSubmit() {
      this.showConfirm({
        yesCallback: this.yesCallback,
        noCallback: null,
        title: "Conferma Invio",
        message: "Vuoi davvero postare?",
        yesLabel: "CERTO",
        noLabel: "ASSOLUTAMENTE NO",
        // yesVariant:
        // noVariant:
        // headerBgVariant:
      });
    },
    onSubmit1() {
      console.debug("onSubmit1");
      //   this.$refs.observer1.validate();
      requestAnimationFrame(() => {
        this.$refs.observer1.reset();
      });
      //   this.$refs.observer1.setErrors({
      //     test: ['This test field is not allowed'],
      //     test1: ['This test1 field is not allowed'],
      //   });
    },
    onSelect(selected) {
      this.resourceId = selected;
      this.fetch();
    },
    fetch() {
      console.debug(">>>>>>>>>>>>>>>>> FETCH <<<<<<<<<<<<<<<<<<<<<");
      const Repo = RepositoryFactory.get(this.repository);
      if (!this.resourceId) {
        this.isLoading = false;
        return;
      }
      this.isLoading = true;
      Repo.show(this.resourceId)
        .then((response) => {
          const data = response.data.data;
          console.debug(`fetch: ${JSON.stringify(data)}`);
          this.details = data;
        })
        .catch((error) => {
          // clear data
          this.form = {};
          console.error(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    yesCallback() {
      console.log("yesCallback!!!");
      console.log(JSON.stringify(this.form, null, 2));
      this.$showSnackbar({
        preset: "info",
        duration: 0,
        text: `Form submitted: ${JSON.stringify(this.form, null, 2)}`,
      });
    },
    noCallback() {
      this.$showSnackbar({
        preset: "info",
        text: "Action canceled!",
      });
    },
    reportError() {
      this.$showSnackbar({
        preset: "info",
        text: "errore inviato!",
        actionText: "Thanks",
        actionTextColor: "#FFFFFF",
      });
    },
    delete403(id = 1) {
      const Repo = RepositoryFactory.get("registry");
      Repo.destroy(id)
        .then((response) => {
          console.log(response.data.data);
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        })
        .finally(() => {});
    },
    sms_show(id = "962399134") {
      let LC = RepositoryFactory.get("lisacomunica");
      LC.sms_show(id)
        .then((response) => {
          this.$showSnackbar({
            preset: "error",
            text: JSON.stringify(response.data.content),
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    sms_index() {
      let LC = RepositoryFactory.get("lisacomunica");
      LC.sms_index()
        .then((response) => {
          this.$showSnackbar({
            preset: "error",
            text: JSON.stringify(response.data.content),
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({ preset: "error", text: errMsg });
        });
    },
    // showSnackbar(args = {}) {
    //   let params = {};
    //   params.text = args.text ? args.text : null;
    //   params.textColor = args.textColor ? args.textColor : '#FFFFFF';
    //   params.pos = args.pos ? args.pos : 'top-center';
    //   params.customClass = args.customClass ? args.customClass : null;
    //   params.width = args.width ? args.width : '200px';
    //   params.showAction = args.showAction === false ? args.showAction : true;
    //   params.actionText = args.actionText ? args.actionText : 'Chiudi';
    //   params.actionTextColor = args.actionTextColor ? args.actionTextColor : '#4CAF50';
    //   params.backgroundColor = args.backgroundColor ? args.backgroundColor : '#323232';
    //   params.duration = args.duration !== undefined ? args.duration : 5000; // 0 is forever
    //   params.onActionClick = args.onActionClick ? () => { args.onActionClick() } : () => {};
    //   params.onClose = args.onClose ? () => { args.onClose() } : () => {};
    //   showSnackbar(params);
    // }
    initEvent() {
      return {
        id: null,
        title: "",
        start: null,
        end: null,
        allDay: false,
        startTime: "08:00",
        endTime: "09:00",
      };
    },
    createTask() {
      this.$bvModal.show("eventCreateModal");
    },
    cancelEventCreation() {
      this.closeCreationDialog();
    },
    closeCreationDialog() {
      this.$bvModal.hide("eventCreateModal");
      this.selectedEvent = this.initEvent();
    },
    createEventId() {
      return parseInt(Math.random() * 10000);
    },
    createEvent() {
      // set new event on calendar
      console.log("CREATE EVENT");
      let title = this.selectedEvent.title;
      let fromDate;
      let toDate;

      // set staryt and end on original event

      if (title) {
        // let eventDate = this.selectInfo.start;
        if (this.selectedEvent.fromDate) {
          fromDate = moment(this.selectedEvent.fromDate, "YYYY-MM-DD").toDate();
        }
        if (this.selectedEvent.toDate) {
          toDate = moment(this.selectedEvent.toDate, "YYYY-MM-DD").toDate();
        }
        let startDate; // = eventDate;
        let endDate; // = eventDate;
        if (fromDate) {
          startDate = fromDate;
        }
        if (toDate) {
          endDate = toDate;
        }

        let body = this.prepareBody(startDate, endDate, this.selectedEvent);
        console.log(" ================= SAVING BODY ===================");
        console.log(body);
        // 2. TODO: call backend
        // Repo.store().then((response) => {
        //   id = response.data.data.id
        //   this.closeCreationDialog();
        // });
      } else {
        console.log("Missing title: cannot add event");
        this.$showSnackbar({
          preset: "error",
          text: `&Egrave; necessario fornire almeno un titolo per l'evento!`,
          actionText: "OK",
          // onClose: () => {
          //   this.closeCreationDialog()
          // }
        });
        return;
      }
      this.closeCreationDialog();
    },
    prepareBody(startDate, endDate, selectedEvent) {
      let allDay = selectedEvent.allDay ? true : false;
      let title = selectedEvent.title;
      let content = selectedEvent.content;
      let start;
      let end;
      let eventDate;
      let Y;
      let M;
      let D;
      if (startDate) {
        // ci sono le date
        if (startDate === endDate || !endDate) {
          // inizio e fine uguali o fine non settao: 1 giorno
          eventDate = startDate;

          Y = eventDate.getFullYear();
          M = eventDate.getMonth();
          D = eventDate.getDate();
          console.log(`Year: ${Y}, Month: ${M}, Day: ${D}`);
          let startHour = "0";
          let startMinute = "0";
          let endHour = "23";
          let endMinute = "59";
          if (!allDay) {
            startHour = selectedEvent.startTime.split(":")[0];
            startMinute = selectedEvent.startTime.split(":")[1];
            endHour = selectedEvent.endTime.split(":")[0];
            endMinute = selectedEvent.endTime.split(":")[1];
          }

          start = `${Y}-${String(M + 1).padStart(2, "0")}-${String(D).padStart(
            2,
            "0"
          )} ${startHour.padStart(2, "0")}:${startMinute.padStart(2, "0")}:00`;
          end = `${Y}-${String(M + 1).padStart(2, "0")}-${String(D).padStart(
            2,
            "0"
          )} ${endHour ? endHour.padStart(2, "0") : ""}:${
            endMinute ? endMinute.padStart(2, "0") : ""
          }:00`;
        } else {
          // inizio e fine diversi, più giorni
          // startDate
          eventDate = startDate;

          Y = eventDate.getFullYear();
          M = eventDate.getMonth();
          D = eventDate.getDate();
          console.log(`Year: ${Y}, Month: ${M}, Day: ${D}`);
          let startHour = "0";
          let startMinute = "0";
          if (!allDay) {
            startHour = selectedEvent.startTime.split(":")[0];
            startMinute = selectedEvent.startTime.split(":")[1];
          }

          start = `${Y}-${String(M + 1).padStart(2, "0")}-${String(D).padStart(
            2,
            "0"
          )} ${startHour.padStart(2, "0")}:${startMinute.padStart(2, "0")}:00`;

          // endDate
          eventDate = endDate;

          Y = eventDate.getFullYear();
          M = eventDate.getMonth();
          D = eventDate.getDate();
          console.log(`Year: ${Y}, Month: ${M}, Day: ${D}`);

          let endHour = "23";
          let endMinute = "59";
          if (!allDay) {
            endHour = selectedEvent.endTime.split(":")[0];
            endMinute = selectedEvent.endTime.split(":")[1];
          }

          end = `${Y}-${String(M + 1).padStart(2, "0")}-${String(D).padStart(
            2,
            "0"
          )} ${endHour ? endHour.padStart(2, "0") : ""}:${
            endMinute ? endMinute.padStart(2, "0") : ""
          }:00`;
        }
      }

      // let class = this.selectedEvent.class;

      // TODO: save the event!!!
      // 1. prepare the payload

      let body = {
        start,
        end,
        title,
        content,
        // class,
        allDay,
      };
      return body;
    },
    canReport(code, type) {
      let r = this.getReport()(code);
      if (!r || r?.users?.length === 0) {
        return false;
      }
      return r.users[0].pivot.allowed.includes(type);
    },
  },
  computed: {
    frm() {
      return this.form.coass.coass_details;
    },
    entryDetailsCreateTotal() {
      console.log("############## entryDetailsCreateTotal ##############");
      console.log(this.form.book_entries.entry_details);
      // if(!Object.keys(this.form.book_entries.entry_details).length) return toLocaleCurrency(0);
      return toLocaleCurrency(
        Object.keys(this.form.book_entries.entry_details)
          .map((key) => {
            return +this.form.book_entries.entry_details[key].amount || 0;
          })
          .reduce(function (sum, i) {
            return sum + i;
          }, 0)
      );
    },
    // entryDetailsCreateTotal() {
    //   console.log("############## entryDetailsCreateTotal ##############");
    //   console.log(this.form.book_entries.entry_details);
    //   // if(!Object.keys(this.form.book_entries.entry_details).length) return toLocaleCurrency(0);
    //   return toLocaleCurrency(
    //     Object.keys(this.form.book_entries.entry_details)
    //       .map((key) => {
    //         return +this.form.book_entries.entry_details[key].amount || 0;
    //       })
    //       .reduce(function (sum, i) {
    //         return sum + i;
    //       }, 0)
    //   );
    // },
    notes_counter() {
      return Object.values(this.notes).reduce(
        (out, inp) => out + inp.length,
        0
      );
    },
    notes_tooltip_content() {
      return Object.values(this.notes).reduce(
        (out, inp) =>
          out + inp.reduce((o, i) => o + "<br/>" + i.date + " " + i.title, ""),
        ""
      );
    },
    entryDetailsEditTotal() {
      console.log("############## entryDetailsEditTotal ##############");
      console.log(this.form2.book_entries.entry_details);
      // if(!Object.keys(this.form.book_entries.entry_details).length) return toLocaleCurrency(0);
      return toLocaleCurrency(
        Object.keys(this.form2.book_entries.entry_details)
          .map((key) => {
            return +this.form2.book_entries.entry_details[key].amount || 0;
          })
          .reduce(function (sum, i) {
            return sum + i;
          }, 0)
      );
    },
    ...mapGetters("auth", ["permissions", "roles"]),
    // labels() {
    //   // REVIEW: il problema è che alcune label non esistono, ad esempio CAP
    //   let labels = Object.assign({}, this.defaultLabels);
    //   const re = /^attribute_(.+)_value/g;
    //   for (const [key, value] of Object.entries(this.beForm)) {
    //     if (key.match(re)) {
    //       let name = re.exec(key);
    //       labels[name[1]] = value.label;
    //     }
    //   }
    //   return labels;
    // },
    // optCountries() {
    //   return this.countries
    // },
    // optProvinces() {
    //   let ret = []
    //   if (this.country){
    //     ret = this.provinces()(this.country)
    //   }
    //   return ret
    // }
  },
  mounted() {
    // this.sms_index();
    // this.isLoading = true;
    // showSnackbar({
    //       preset: "info",
    //       text: `TableRef: ${this.tableRef};$refs: ${JSON.stringify(this.$refs)}`,
    //       actionText: "OK",
    //     });
    // this.$refs[this.tableRef].fetch(); //.finally(() => this.isLoading = false);
    // this.uid = this._uid;
    // this.fetch();
    // se non chiamo fetch, devo disattivare isLoading manualmente...
    // this.treasury_options = this.beForm['entry_details'].
    // ENTRY DETAILS CREATE
    // DYNAMICALLY ADD ELEMENTS
    // getTreasuries is called by the mixin (EntryDetailsMixin)
    // console.debug("this.getTreasuries()() = ", this.getTreasuries()());
    // this.treasury_options = this.getTreasuries()();
    // add the form v-model for each possibly selectable treasury_options
    // this.initCreateEntryDetail(this.form.book_entries.entry_details);
    // this.isLoading = false;
  },
  created() {
    this.notifyQueue({ text: "Test", path: "About", level: 0 });
    this.notifyQueue({ text: "About", path: "About", level: 1 });
    // this.isLoading = true;

    // load entry details for book_entry 11
    const Repo = RepositoryFactory.get("entry_detail");
    Repo.index("byBookEntry[id]=11&byTreasury[code]=!PR,!EN")
      .then((response) => {
        const data = response.data.data;
        // this.beForm[repository] = data.fields;
        this.entryDetails = data;
      })
      .catch((error) => {
        console.error(error);
      });

    this.fetchShowForm(this.repository);
  },
  beforeMount() {
    // this.isLoading = true;
    // this.$set(this.optCountries, this.countries, null);
    console.log("*************  BEFORE MOUNT *************");
    this.optCountries = this.countries();
    // this.country = this.optCountries.find(
    //   (c) => c.text == this.defaultCountryName
    // ).value;
    // this.$set(this.optProvinces, this.provinces()(this.country), null);
    this.optProvinces = this.provinces()(this.country);

    // // DEBUG simulazione della chiamata this.fetchEditForm("report", id)
    // let Repo = RepositoryFactory.get("report");
    // Repo.test().then((response) => {
    //   console.log("response: ", response);
    //   const data = response.data;
    //   let repository = "report";
    //   let keys = getFormFieldsKeys(data.fields); // ["secret", "user_id"];
    //   let subKeys;
    //   this.beForm[repository] = {};
    //   this.beRules[repository] = {};
    //   this.$set(this.form, repository, {});
    //   for (const key of keys) {
    //     switch (key) {
    //       case "request_input":
    //         subKeys = getFormFieldsKeys(data.fields[key]);
    //         this.beForm[repository][key] = {};
    //         this.beRules[repository][key] = {};
    //         this.$set(this.form[repository], key, {});
    //         for (const subKey of subKeys) {
    //           this.beForm[repository][key][subKey] = data.fields[key][subKey];
    //           // // this.beForm[repository][subKey] = data.fields[key][subKey];
    //           // this.beRules[repository][key][subKey] = data.rules[key][subKey];
    //           this.$set(this.form[repository][key], subKey, null);
    //         }
    //         break;
    //       default:
    //         this.beForm[repository][key] = data.fields[key];
    //         // this.beRules[repository][key] = data.rules[key];
    //         this.$set(this.form[repository], key, null);
    //     }
    //   }
    //   this.isLoading = false;
    // });
    // this.fetchEditForm("report", 1)
    //   .then(() => {
    //     console.log("fetch edit report");
    //   })
    //   .catch((error) => {
    //     let errMsg = this.$getErrorMessage(error);
    //     this.$showSnackbar({
    //       preset: "error",
    //       text: `${errMsg}`,
    //     });
    //   });
  },
};
</script>
<style lang="scss" scoped>
p {
  margin-bottom: 0.5rem;
  color: #ddd;
}
span {
  display: block;
}
// table tr th,
// td {
//   width: 10%;
//   padding: 2px 4px;
// }
.notes-counter {
  background-color: #fff;
  width: 20px;
  height: 20px;
  border: solid 1px #ddd;
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.tooltip-inner) {
  text-align: left !important;
  white-space: pre-wrap;
}
.verticalLine {
  border-right: 8px solid #f9991e;
}
// table tr td {
//   border-right: 3px solid orangered;
//   color: black
// }

// table tr td:last-of-type {
//   border: none;
// }
// :deep(.red) {
//   background-color: red;
// }
</style>
