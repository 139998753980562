// import { showSnackbar } from "@/utils/messages";
import { mapGetters } from "vuex";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  data: () => ({
    coassData: [],
    coass_options: [],
    coassCode: [],
    enableEditCoassDetailsComp: false, // show/hide the edit comp
    coassDetailsCompEditableConf: {}, // user's operations
    editCoassDetailsCompToggle: false, // sync by toggle button Modifica/Annulla
    deletableCoassDetailIds: [], // list of ids to be removed by the submit
  }),
  beforeMount() {
    const Repo = RepositoryFactory.get("insurer_participant");
    Repo.index("perPage=0").then((response) => {
      this.coassData = response.data.data;
      this.coassCode = this.coassData.map((el) => {
        return {
          id: el.id,
          code: el.code,
        };
      });
      // old part
      this.setCoassCodes();
      this.$set(this, "coass_options", this.getCoassOptions());
      this.initCreateCoassDetail(this.form.coass.coass_details);
    });
  },
  methods: {
    ...mapGetters("auth", {
      getTreasuries: "treasuries",
    }),
    addCreateCoassDetailsComp(form) {
      // 10/08/2022
      // let options = this.coass_options;
      // #559
      this.$set(this, "coass_options", this.getCoassOptions());
      let n = this.coassdtls.length;
      // there are already n rows...
      if (n === this.coass_options.length) {
        // ...cannot add any more items
        return;
      }
      // there's already a new treasury with blank net...

      // WARNING: 10/08/2022 id non è indicatore di chiave per form
      // questo check non è consistente e potrebbe andare in errore
      // console.log("coassDetails: ", this.coassDetails);
      if (
        Object.keys(this.coassdtls).filter((x) => {
          let id = this.coassdtls[x].id;
          return form[id].type === null;
        }).length > 0
      ) {
        // ...cannot add any more items
        return;
      }
      // range ids
      let rangeIds = Array.from(
        { length: this.coass_options.length },
        (v, k) => k
      );
      // get the already used ids
      let usedIds = this.coassdtls.map((f) => f.id);
      // get the ids not already used
      let freeIds = rangeIds.filter((id) => !usedIds.includes(id));

      let id = freeIds.reverse().pop();
      this.coassdtls.push({
        id: id, // later, set as the id of the select component named "type"
        selected: null, // later, will keep the selected value (from the select component named "type")
        options: this.coass_options, // later, set as otpions of the select component named "type"
      });
    },
    addEditCoassDetailsComp(form) {
      let n = Object.keys(this.coassdtlscfg).length;
      // there are already n rows...
      if (n === this.coass_options.length) {
        // ...cannot add any more items
        return;
      }
      // there's already a new treasury with blank net...
      if (
        Object.keys(this.coassdtlscfg).filter((x) => {
          let id = this.coassdtlscfg[x].id;
          return form[id].type === null;
        }).length > 0
      ) {
        // ...cannot add any more items
        return;
      }
      let options = this.coass_options;
      // filter coass_options removing the ones already selected (eg looping in this.form.book_entries.entry_details[formaN].type)
      // options = this.coass_options.filter(
      //   (elem) =>
      //     !Object.keys(this.form2.book_entries.entry_details)
      //       .map((f) => this.form2.book_entries.entry_details[f].type)
      //       .includes(elem.value)
      // );
      // NOTE: newIndex must be really a new one: get the max id among those starting with "NewIndex-".
      // Cannot simply rely on length + 1 !!!
      let newIndex =
        parseInt(
          Object.keys(this.coassdtlscfg)
            .filter((c) => c.startsWith("NewIndex-"))
            .reduce(function (prev, current) {
              return parseInt(prev.slice(9)) > parseInt(current.slice(9))
                ? prev.slice(9)
                : current.slice(9);
            }, "0")
        ) + 1;
      // if not already configured...
      if (!Object.keys(this.coassdtlscfg).includes(`NewIndex-${newIndex}`)) {
        this.coassdtlscfg[`NewIndex-${newIndex}`] = {
          id: `NewIndex-${newIndex}`, // later, set as the id of the select component named "type"
          selected: null, // later, will keep the selected value (from the select component named "type")
          options: options, // later, set as options of the select component named "type"
        };
      }
      if (!Object.keys(form).includes(`NewIndex-${newIndex}`)) {
        this.$set(form, `NewIndex-${newIndex}`, {});
        this.$set(form[`NewIndex-${newIndex}`], "share", null);
        this.$set(form[`NewIndex-${newIndex}`], "net", null);
        this.$set(form[`NewIndex-${newIndex}`], "tax", null);
        this.$set(form[`NewIndex-${newIndex}`], "type", null);
      }
    },
    selectCreateCoassDetail(form, selected, id) {
      let foundIndex;
      // if already selected among all coassDetails, stop!
      foundIndex = this.coassDetails.findIndex((x) => x.selected == selected);
      if (foundIndex !== -1) {
        this.$showSnackbar({
          preset: "info",
          text: `Attenzione: coassicurato ${selected} già selezionato`,
          showAction: true,
          actionText: "Rimuovi",
          duration: 5000,
          onActionClick: () =>
            this.unselectCreateCoassDetail(form, selected, id),
        });
      }
      // set the selected prop on this.coassDetails
      foundIndex = this.coassDetails.findIndex((x) => x.id == id);
      if (foundIndex !== -1) {
        this.coassDetails[foundIndex].selected = selected;
        // // imposta il remainder
        // if (this.$refs["edRef"].enableRemainder) {
        //   const remainder = this.$refs["edRef"].remainder;
        //   if (!form[id].net) {
        //     // evita che se cambio la tendina mette 0, poi se la ricambio mette remainder
        //     this.$set(form[id], "net", remainder);
        //   }
        // }
        // aggiungo un nuovo coassicurato
        this.$nextTick(() => {
          // devo dargli il tempo...
          this.addCreateCoassDetailsComp(form);
        });
      }
    },
    selectEditCoassDetail(form, selected, id) {
      let foundIndex;
      // if already selected among all coassDetails, stop!
      foundIndex = Object.keys(this.coassdtlscfg).findIndex(
        (x) => this.coassdtlscfg[x].selected == selected
      );
      if (foundIndex !== -1) {
        this.$showSnackbar({
          preset: "info",
          text: `Attenzione: coassicurato ${selected} già selezionato`,
          showAction: true,
          actionText: "Rimuovi",
          duration: 5000,
          onActionClick: () => this.unselectEditCoassDetail(form, selected, id),
        });
      }
      // set the selected prop on this.coassDetails
      foundIndex = Object.keys(this.coassdtlscfg).findIndex(
        (x) => this.coassdtlscfg[x].id == id
      );
      if (foundIndex !== -1) {
        this.coassdtlscfg[id].selected = selected;
        // // imposta il remainder
        // if (this.$refs["edRef"].enableRemainder) {
        //   const remainder = this.$refs["edRef"].remainder;
        //   if (!form[id].net) {
        //     // evita che se cambio la tendina mette 0, poi se la ricambio mette remainder
        //     this.$set(form[id], "net", remainder);
        //   }
        // }
        // aggiungo un nuovo coassicurato
        this.$nextTick(() => {
          this.addEditCoassDetailsComp(form);
        });
      }
    },
    deleteCreateCoassDetail(form, id) {
      // retrieve the removed value (the BaseSelect selected option related to this id), then call unselectTreasury
      let removed = this.coassdtls.find((t) => t.id === id).selected;
      this.unselectCreateCoassDetail(form, removed, id);
    },
    deleteEditCoassDetail(form, id) {
      console.log("deleteEditCoassDetail form", form);
      console.log("deleteEditCoassDetail id", id);
      // add to this.deletableCoassDetailIds if the id is a valid id (not the string starting with 'NewIndex-...)
      if (!isNaN(parseInt(id))) {
        if (!this.deletableCoassDetailIds.includes(id)) {
          this.deletableCoassDetailIds.push(id);
        }
      }
      delete this.coassdtlscfg[id];
      this.$delete(form, id);
      this.$nextTick(() => {
        // devo dargli il tempo...
        this.addEditCoassDetailsComp(form);
      });
    },
    unselectCreateCoassDetail(form, removed, id) {
      this.$set(form[id], "share", null); // share
      this.$set(form[id], "net", null); // net
      this.$set(form[id], "tax", null); // tax
      this.$set(form[id], "type", null);
      // remove all then reset this.coassDetails by pushing back again all but the one removed
      let coassDetails = [];
      let selected = [];

      for (let i = 0; i < Object.keys(form).length; i++) {
        if (form[i].type /* && this.form.book_entries.entry_details[i].net*/) {
          let options = this.coass_options.filter(
            (elem) => !selected.includes(elem.value)
          );
          selected.push(form[i].type);
          // selected.push(removed)
          coassDetails.push({
            id: i,
            selected: form[i].type,
            options: options,
          });
          // // imposta il remainder
          // if (this.$refs["edRef"] && this.$refs["edRef"].enableRemainder) {
          //   console.log("OK");

          //   const remainder = this.$refs["edRef"].remainder;
          //   if (!form[i].net) {
          //     // evita che se cambio la tendina mette 0, poi se la ricambio mette remainder
          //     this.$set(form[i], "net", remainder);
          //   }
          // }
        }
      }
      // update the array for drawing v-for loop
      this.coassdtls = coassDetails;
      this.addCreateCoassDetailsComp(form);
    },
    unselectEditCoassDetail(form, removed, id) {
      this.$set(form[id], "type", null);
      this.deleteEditCoassDetail(form, id);
    },
    initCreateCoassDetail(form /* , hasNegativeGross = false */) {
      /* // if gross is negative...(CREATE MODE)
      if (hasNegativeGross) {
        console.debug(
          "CDMixin changeCoassDetails: negative gross, can add AC treasury"
        );
        this.extraTreasuries.push("AC");
        this.$set(this, "skipDefault", []); // or AC will be skipped
      } */

      this.$set(this, "coass_options", this.getCoassOptions());
      for (let i = 0; i < this.coass_options.length; i++) {
        this.$set(form, i, {});
        this.$set(form[i], "share", null);
        this.$set(form[i], "net", null);
        this.$set(form[i], "tax", null);
        this.$set(form[i], "type", null);
      }
      this.addCreateCoassDetailsComp(form);
    },
    setCoassCodes() {
      this.coassCode = this.coassData.map((el) => {
        return {
          id: el.id,
          code: el.code,
        };
      });
    },
    getCoassOptions() {
      let options = [];
      options = this.coassData.map((e) => ({
        value: e.id,
        text: `${e.code} - ${e.title}`,
        code: `${e.code}`,
      }));
      return options;
    },
    // EDIT COASS
    changeCoassDetails(form) {
      // reset the edit conf
      let tmpConf = {};
      // reset deletableCoassDetailIds
      this.deletableCoassDetailIds = [];
      // reset the details, if any
      const length = Object.keys(form).length;
      if (length) {
        const keys = Object.keys(form);
        for (let i = 0; i < length; i++) {
          let key = keys[i];
          this.$delete(form, key);
        }
      }
      // // if gross is negative... (EDIT MODE)
      // if (this.details && this.details.gross < 0) {
      //   console.debug(
      //     "CDMixin changeCoassDetails: negative gross, can add AC treasury"
      //   );
      //   this.extraTreasuries.push("AC");
      //   this.$set(this, "skipDefault", []); // or AC will be skipped
      // }
      // // NEW 10/08/2022
      // this.initCreateCoassDetail(form.book_entries.entry_details);

      this.$set(this, "coass_options", this.getCoassOptions());

      // set the model for all details
      for (const detail of this.coassDetails || []) {
        // TODO: mappare i campi pivot correttamente (il chiamante deve )
        this.$set(form, `${detail.id}`, {});
        this.$set(form[detail.id], "type", detail.id);
        this.$set(form[detail.id], "share", parseFloat(detail.pivot.share));
        this.$set(
          form[detail.id],
          "net",
          parseFloat(detail.pivot.gross) || 0.0
        );
        this.$set(form[detail.id], "tax", parseFloat(detail.pivot.tax) || 0.0);

        tmpConf[detail.id] = {
          id: detail.id, // later, set as the id of the select component named "type"
          selected: detail.id, // later, will keep the selected value (from the select component named "type")
          options: this.coass_options, // later, set as options of the select component named "type"
        };
      }
      // init the edit details list
      this.coassdtlscfg = tmpConf;
      if (this.editCoassDetailsCompToggle) {
        // aggiungo un nuovo coassicurato
        console.debug("!!! changeCoassDetails: aggunto dettaglio vuoto !!!");
        this.$nextTick(() => {
          // devo dargli il tempo...
          this.addEditCoassDetailsComp(form);
        });
      }
    },
  },
  computed: {
    coassdtls: {
      set(val) {
        this.coassDetails = val;
      },
      get() {
        return this.coassDetails;
      },
    },
    coassdtlscfg: {
      set(val) {
        this.coassDetailsCompEditableConf = val;
      },
      get() {
        return this.coassDetailsCompEditableConf;
      },
    },
  },
};
