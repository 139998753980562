<template>
  <div
    v-if="
      anag_status_registry === section_status_registry ||
        section_status_registry === 'Standard'
    "
  >
    <b-card>
      <div
        slot="header"
        class="d-flex justify-content-between align-items-center"
      >
        <p class="mb-0 d-inline-block align-middle">
          {{ header }}
        </p>
        <b-btn
          v-if="buttonhtml"
          :disabled="disabled"
          @click="edit()"
          size="sm"
          variant="lisaweb"
        >
          {{ buttonhtml }}
        </b-btn>
      </div>

      <b-row v-for="(field, index) in fields" :key="index">
        <div class="col-md-4 border-top">
          <!-- Se non trovo la label standard, prendo quella 'custom' -->
          {{ labels[field.label] ? labels[field.label] : field.label }}
        </div>
        <div class="col-md-8 border-top border-left" v-if="editable">
          <b-form-input
            :id="`input-${index}`"
            :name="`input-${index}`"
            type="text"
            v-model="form[field.value]"
          ></b-form-input>
        </div>
        <div class="col-md-8 border-top border-left" v-else>
          {{ texts(field.value) }}
        </div>
      </b-row>

      <div class="py-2 mb-4" v-show="visible">
        <b-btn variant="lisaweb" class="" size="sm" @click="onSave()">
          Salva
        </b-btn>
      </div>
    </b-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      dataCopy: null,
      disabled: false,
      visible: false,
      editable: false,
    };
  },
  props: {
    section_status_registry: null,
    anag_status_registry: null,
    header: String,
    fields: {
      type: Array,
    },
    data: {
      type: Object,
    },
    labels: {
      type: Object,
    },
    buttonhtml: String,
  },
  beforeMount() {
    this.dataCopy = Object.assign({}, this.data);
  },
  methods: {
    edit(e) {
      console.debug("showCard edit", e, this.fields);

      this.fields.forEach((field) => {
        let value;
        let parts = field.value.split(".");
        let length = parts.length;
        if (length > 1) {
          value = parts.reduce((acc, part) => acc && acc[part], this.dataCopy);
        } else {
          value = this.dataCopy[field.value];
        }
        this.form[field.value] = value;
      });
      this.disabled = true;
      this.visible = true;
      this.editable = true;
    },
    onSave() {
      // console.debug("showCard onSave", this.fields);
      // // TODO: save!!!
      // Object.keys(this.form).forEach(key => {
      //   this.dataCopy[key] = this.form[key] // Unexpected mutation of "data" prop
      // })
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          const value = this.form[key];
          this.setValue(this.dataCopy, key, value);
          // console.debug("K: ", key);
          // console.debug("FV: ", value);
        }
      }
      this.disabled = false;
      this.visible = false;
      this.editable = false;
    },
    /**
     * Set a value based on a dot-notation key.
     *
     * @param obj
     * @param keys
     * @param value
     */
    setValue(obj, keys, value) {
      keys = typeof keys === "string" ? keys.split(".") : keys;
      const key = keys.shift();
      if (keys.length === 0) {
        obj[key] = value;
        return;
      } else if (!Object.hasOwnProperty.call(obj, key)) {
        obj[key] = {};
      }
      this.setValue(obj[key], keys, value);
    },
    clear() {
      this.disabled = false;
      this.visible = false;
      this.editable = false;
    },
  },
  computed: {
    texts() {
      return (field) => {
        let value;
        let parts = field.split(".");
        let length = parts.length;
        if (length > 1) {
          // let property = this.details
          // for ( let i = 0; i < length; i++ ) {
          //   property = property[parts[i]];
          // }
          // value = property
          value = parts.reduce((acc, part) => acc && acc[part], this.data);
        } else {
          value = this.data[field];
        }
        return value;
      };
    },
  },
};
</script>
<style scoped></style>
