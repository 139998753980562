<template>
  <!-- DYNAMIC inputs from custom attributes -->
  <div>
    <b-row
      v-for="(input, index) in inputs[groupingField] || inputs"
      :key="index"
    >
      <!-- input text -->
      <div
        class="form-group col-md-6"
        v-if="getInputType(beForm[input]) === 'text'"
      >
        <base-input
          :name="beForm[input].label"
          :vid="input"
          :label="beForm[input].label"
          v-model="inputVal[input]"
          :rules="getRules(input)"
        />
      </div>
      <!-- input textarea -->
      <div
        class="form-group col-md-6"
        v-else-if="getInputType(beForm[input]) === 'textarea'"
      >
        <base-textarea
          :name="beForm[input].label"
          :vid="input"
          :label="beForm[input].label"
          v-model="inputVal[input]"
          :rules="getRules(input)"
          rows="4"
          maxrows="4"
        />
      </div>
      <!-- input select REVIEW: single choice? multi choice?-->
      <div
        class="form-group col-md-6"
        v-else-if="getInputType(beForm[input]) === 'select'"
      >
        <base-select
          :name="beForm[input].label"
          :vid="input"
          :label="beForm[input].label"
          v-model="inputVal[input]"
          :options="beForm[input].options"
          :rules="getRules(input)"
          :taggable="false"
          :multiple="false"
          :closeOnSelect="true"
        />
      </div>
      <!-- input datepicker -->
      <div
        class="form-group col-md-6"
        v-else-if="getInputType(beForm[input]) === 'datepicker'"
      >
        <base-datepicker
          :name="beForm[input].label"
          :vid="input"
          :label="beForm[input].label"
          v-model="inputVal[input]"
          :rules="getRules(input)"
        />
      </div>
      <!-- input timepicker -->
      <div
        class="form-group col-md-6"
        v-else-if="getInputType(beForm[input]) === 'timepicker'"
      >
        <base-timepicker
          :name="beForm[input].label"
          :vid="input"
          :label="beForm[input].label"
          v-model="inputVal[input]"
          :rules="getRules(input)"
        />
      </div>
      <!-- input checkbox (select) REVIEW: single choice? multi choice?-->
      <div
        class="form-group col-md-6"
        v-else-if="getInputType(beForm[input]) === 'checkbox'"
      >
        <!-- <base-checkbox
          :name="beForm[input].label"
          :vid="input"
          :label="beForm[input].label"
          v-model="inputVal[input]"
          :rules="getRules(input)"
        /> -->
        <base-select
          :name="beForm[input].label"
          :vid="input"
          :label="beForm[input].label"
          v-model="inputVal[input]"
          :options="beForm[input].options"
          :rules="getRules(input)"
          :taggable="false"
          :multiple="false"
          :closeOnSelect="true"
        />
      </div>
      <!-- input radio -->
      <div
        class="form-group col-md-6"
        v-else-if="getInputType(beForm[input]) === 'radio'"
      >
        <base-radio
          :name="beForm[input].label"
          :vid="input"
          :label="beForm[input].label"
          v-model="inputVal[input]"
          :options="beForm[input].options"
          :rules="getRules(input)"
          stacked
        />
      </div>
    </b-row>
  </div>
</template>
<script>
import { getInputType } from "@/utils/forms";
import { prepareRules } from "@/utils/validation";
import BaseTextarea from "@/components/form/BaseTextarea";
import BaseSelect from "@/components/form/BaseSelect";
// import BaseCheckbox from "@/components/form/BaseCheckbox";
import BaseInput from "@/components/form/BaseInput";
import BaseRadio from "@/components/form/BaseRadio";
import BaseDatepicker from "@/components/form/BaseDatepicker";
// import TestDatepicker from "@/components/form/TestDatepicker";
import BaseTimepicker from "@/components/form/BaseTimepicker";
// import BaseDynamicInput from "@/components/form/BaseDynamicInput";
export default {
  props: {
    value: undefined,
    inputs: Array,
    groupingField: null,
    beForm: {
      type: Object,
      default() {
        return {};
      },
    },
    beRules: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    BaseTextarea,
    BaseSelect,
    // BaseCheckbox,
    BaseInput,
    BaseRadio,
    BaseDatepicker,
    // TestDatepicker,
    BaseTimepicker,
    // BaseDynamicInput,
  },
  methods: {
    getInputType,
    getRules(field) {
      let rules = {};
      if (this.beRules.length) {
        rules = prepareRules(this.beRules[field]);
      }
      return rules;
    },
  },
  computed: {
    inputVal: {
      get() {
        // console.debug(`inputVal GET: ${JSON.stringify(this.value)}`)
        return this.value;
      },
      set(val) {
        // console.debug(`inputVal SET: ${JSON.stringify(val)}`)
        this.$emit("input", val);
      },
    },
  },
};
</script>
