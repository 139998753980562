<template>
  <ValidationProvider
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <!-- using the slot, can append a button -->
      <div class="input-group mb-3">
        <b-form-input
          :id="`input-${id}`"
          :name="`input-${id}`"
          :type="type"
          v-model="inputVal"
          :state="getValidationState({ dirty, validated, valid, errors })"
          :aria-describedby="`input-${id}-live-feedback`"
          @blur="onBlur"
        ></b-form-input>
        <slot></slot>
        <b-form-invalid-feedback :id="`input-${id}-live-feedback`">{{
          errors[0]
        }}</b-form-invalid-feedback>
      </div>
    </b-form-group>
  </ValidationProvider>
</template>
<script>
// import {
//     extend
// } from "vee-validate";
export default {
  props: {
    value: String,
    name: String,
    vid: String,
    label: String,
    type: {
      default: "text",
      type: String,
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      id: null,
    };
  },
  mounted() {
    this.id = this._uid;
    // extend('backend', {
    //     getMessage: (field) => `The ${field} is not validated by backend.`,
    //     // Custom validation rule
    //     validate: (value) => new Promise(resolve => {
    //         resolve({
    //             valid: value && (this.backendValidate(value))
    //         });
    //     })
    // });
  },
  computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  // watch: {
  //     value() {
  //         this.$emit('input', this.value);
  //     }
  // },
  methods: {
    getValidationState1({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      // return dirty || validated ? valid : null;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
    onBlur(e) {
      console.debug("input onBlur", e);
      this.$emit("blur", e);
    },
  },
};
</script>
