<template>
  <!-- <div v-if="!isLoading"> -->
  <div class="mt-2">
    <!-- Main table element -->
    <b-table
      :busy="isLoading"
      bordered
      head-variant="light"
      :no-local-sorting="true"
      :items="items"
      :fields="getAllFields"
      :current-page="currentPage"
      per-page="0"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      stacked="md"
      show-empty
      empty-text="Nada"
      small
      @sort-changed="sortingChanged"
      :tbody-tr-class="rowClass"
    >
      <template #table-busy>
        <div class="text-center my-2">
          <base-icon name="loading" width="35" height="35" />
          <p>Operazione in corso...</p>
        </div>
      </template>
      <!-- <template #cell(colored)="{ item }">
      <b-icon v-if="item.status_registry.value === 0" :title="item.status_registry.text" icon="exclamation-circle-fill" variant="success"></b-icon>
      <b-icon v-if="item.status_registry.value === 1" :title="item.status_registry.text" icon="exclamation-circle-fill" variant="danger"></b-icon>
    </template> -->

      <template #cell(rowSelector)="row">
        <div class="action-buttons">
          <b-button
            size="sm"
            variant="lisaweb"
            @click="onChosen(row.item)"
            class="mt-1 mr-1"
            title="Seleziona"
            >Aggiorna
            <!-- <b-icon icon="check-circle"></b-icon> -->
          </b-button>
        </div>
      </template>
      <!-- @row-clicked="(item) => $set(item, '_showDetails', !item._showDetails)" -->
      <template #cell(insurer_id)="{ item }">
        <!-- <b-form-input v-model="item.insurer_id" type="text"></b-form-input> -->
        <!-- <base-input
          vid="insurer_id"
          name="insurer_id"
          v-model="item.insurer_id"
          :readonly="item.insurer_id % 2"
        /> -->
        <base-currency
          name="insurer_id"
          v-model="item.insurer_id"
          :options="{ currency: 'EUR', locale: 'it-IT' }"
          @change="onCellChange(item.id)"
        />
        <!-- :disabled="item.insurer_id % 2 ? true : false" -->
        <!--  -->
        <!-- -- {{ item.insurer_id }} -- -->
      </template>

      <template slot="bottom-row" slot-scope="data">
        <td v-for="(field, i) in data.fields" :key="i">
          <b
            >{{ i === 0 ? "Totale" : "" }}
            {{
              i !== 0 && field.key === "insurer_id"
                ? toLocaleCurrency(totalizeFields(items, field.key))
                : null
            }}
            {{
              i !== 0 && field.key === "attributables.CUST1"
                ? toLocaleCurrency(totalizeFields(items, field.key))
                : null
            }}
            {{
              i !== 0 && field.key === "attributables.CUST2"
                ? totalQuantity(items, field.key)
                : null
            }}
          </b>
        </td>
      </template>
      <!-- hasChecks: start -->
      <!-- slot1: check all / uncheck all header control -->
      <template v-slot:head(check)="" v-if="hasChecks && !isCheckExclusive">
        <!-- <span v-html="data.label"></span> -->
        <base-checkbox
          v-model="checkAll"
          name="all"
          class="check"
          @change="onSelectAllRows"
        />
      </template>
      <!-- slot2: checkbox in each row -->
      <template #cell(check)="row" v-if="hasChecks">
        <!-- <b-form-checkbox
          name="check"
          v-model="row.item.check"
          @input="onSelectRow($event, row.index, row.item)"
        /> -->
        <base-checkbox
          name="check"
          class="check"
          v-model="row.item.check"
          @input="onSelectRow($event, row.index, row.item)"
        />
      </template>
      <!-- hasChecks: end -->

      <!-- <template slot="top-row" v-if="hasChecks">
      <td colspan=2 class="check">
        <b-form-checkbox
            v-model="checkAll"
            name="all"
            @input="onSelectAllRows"
          >{{ checkAll ? 'Deseleziona tutti' : 'Seleziona tutti' }}
        </b-form-checkbox>
      </td>
    </template> -->

      <!-- <template v-for="field in customAttributes" v-slot:[`cell(${field.key})`]="{ item }">
         {{ item.value }}
     </template> -->
      <!-- <template #cell(name)="row">
        {{ row.value.first }} {{ row.value.last }}
      </template> -->

      <!-- questo OK -->
      <!-- <template #cell(secret)="row">
         <div class="w-100 text-truncate">{{ row.value }}</div>
        </template> -->

      <!-- questo NO -->
      <!-- <template slot="secret" slot-scope="{ value }">
        <div class="w-100 text-truncate">{{ value }}</div>
      </template> -->

      <!-- così funziona, ma dovrei passare user come field invece che user.email -->
      <!-- <template #cell(user)="row">
         <div class="w-100 text-truncate">{{ row.value.email }}</div>
        </template> -->

      <!-- così funziona, ma eslint me lo segnala come errore -->
      <!-- eslint-disable-next-line -->
      <!-- <template #cell(user.email)="row">
         <div class="w-75 text-truncate">{{ row.value }}</div>
        </template> -->
      <!-- <template #cell(tutte_le_date)="data">
        <ul>
          <li>{{ data.item.created_at }}</li>
          <li>{{ data.item.updated_at }}</li>
        </ul>
      </template> -->
      <!-- <template slot="user_id" slot-scope="{ value }"> -->
      <!-- <template #cell(check_me)="row">
        <b-button @click="test(`${row.item.id}`)">ID: {{ row.item.attributables.SURN }}</b-button>
      </template> -->
      <template #cell(selezionato)="{ item }">
        <base-checkbox
          name="selected"
          class="check"
          v-model="item.selected"
          chk_val="1"
          unchk_val="0"
          @input="onSelezionato($event, item)"
        />
        <!-- <b-form-checkbox
          :id="`input-${item.id}`"
          :name="`input-${item.id}`"
          value=1
          unchecked-value=0
          v-model="item.selezionato"
          @input="onSelectRow($event, item)"
          ></b-form-checkbox
        > -->
        <!-- @mouseup.native="putCheck(item)" -->
      </template>

      <template #cell(actions)="row">
        <div class="action-buttons">
          <!-- info modal -->
          <!-- <b-button
 v-if="actions.includes('infomodal')"
 size="sm"
 variant="lisaweb"
 @click="info(row.item, row.index, $event.target)"
 class="mt-1"
 title="Info modale"
 >
 <b-icon icon="eye-fill"></b-icon>
 </b-button> -->

          <!-- details (modal) -->
          <b-button
            v-if="actions.includes('infomodal')"
            size="sm"
            variant="lisaweb"
            @click="openModal(row.item, row.index, $event.target)"
            class="mt-1 mr-1"
            title="Modale Dettagli"
          >
            <b-icon icon="eye"></b-icon>
          </b-button>

          <!-- details (accordion) -->
          <b-button
            v-if="actions.includes('details')"
            size="sm"
            variant="lisaweb"
            @click="row.toggleDetails"
            class="mt-1 mr-1"
            :title="row.detailsShowing ? 'Chiudi dettagli' : 'Apri dettagli'"
          >
            <!-- {{ row.detailsShowing ? "Hide" : "Show" }} Details -->
            <b-icon v-if="row.detailsShowing" icon="chevron-up"></b-icon>
            <b-icon v-else icon="chevron-down"></b-icon>
          </b-button>

          <!-- edit -->
          <b-button
            v-if="actions.includes('edit')"
            size="sm"
            variant="lisaweb"
            @click="onEdit(row.item.id)"
            class="mt-1 mr-1"
            title="Modifica"
          >
            <b-icon icon="pencil-square"></b-icon>
          </b-button>

          <!-- destroy -->
          <b-button
            v-if="actions.includes('destroy')"
            size="sm"
            variant="lisaweb"
            @click="onDestroy(row.item.id)"
            class="mt-1 mr-1"
            title="Elimina"
          >
            <b-icon icon="trash"></b-icon>
          </b-button>
        </div>
      </template>

      <template #row-details="row">
        <b-card>
          <!-- <ul>
            <li v-for="(value, key) in row.item" :key="key">
              {{ key }}: {{ value }}
            </li>
          </ul> -->
          <b-row class="mb-2" v-for="(value, key) in row.item" :key="key">
            <b-col sm="3" class="text-sm-right"
              ><b>{{ key | capitalize }}:</b></b-col
            >
            <b-col>{{ value }}</b-col>
          </b-row>
        </b-card>
      </template>
    </b-table>
    <!-- server pagination -->
    <b-row align-h="end">
      <!-- server pagination -->
      <div class="show-text">
        <p>Mostra</p>
      </div>

      <div class="group">
        <b-form-group
          label-for="per-page-select"
          label-cols-sm="3"
          label-cols-md=""
          label-cols-lg="3"
          label-size="sm"
          md="auto"
        >
          <b-form-select
            id="per-page-select"
            v-model="perPage"
            :options="pageOptions"
            style="width: 68px"
            size="sm"
            @change="onPageChange(1)"
            :disabled="!items.length"
          ></b-form-select>
        </b-form-group>
      </div>

      <div class="page">
        <p>di {{ totalItems }} per pagina</p>
      </div>
      <div class="pagination" v-if="totalItems">
        <b-pagination
          v-model="currentPage"
          limit="3"
          :total-rows="totalItems"
          :per-page="perPage"
          size="sm"
          @change="onPageChange"
        ></b-pagination>
      </div>
    </b-row>
    <!-- Info modal -->
    <b-modal
      :id="infoModal.id"
      :title="infoModal.title"
      size="xl"
      ok-only
      ok-variant="lisaweb"
      header-bg-variant="lisaweb"
      scrollable
      @hide="resetInfoModal"
    >
      <pre>{{ infoModal.content }}</pre>
    </b-modal>
    <!-- </b-container> -->
  </div>
  <!-- <div v-else class="d-flex justify-content-center mb-3">
    <b-spinner label="Loading..." variant="warning"></b-spinner>
  </div> -->
</template>

<script>
import template from "./template";
import BaseIcon from "@/components/BaseIcon";
import BaseCheckbox from "@/components/form/BaseCheckbox";
// import BaseInput from "@/components/form/BaseInput";
import BaseCurrency from "@/components/form/BaseCurrency";
import { toLocaleCurrency } from "@/utils/strings";
// import { getAncillaryEffectiveStatus } from "@/utils/dates";

const statusCSS = {
  0: "effective-status-0", // scaduto (ROSA)
  1: "effective-status-1", // ultimo giorno (ARANCIONE)
  2: "effective-status-2", // in scadenza (GIALLO)
  3: "effective-status-3", // valido (NEUTRO)
};

export default {
  name: "TestTable",
  extends: template,
  data() {
    return {
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  components: {
    BaseIcon,
    BaseCheckbox,
    // BaseInput,
    BaseCurrency,
  },
  computed: {
    isChecked() {
      return (item) => {
        // item.insurer_id = (item.attributables.NAME.length % 2) ? "true" : "false"
        // console.debug(`isChecked: name length ${item.attributables.NAME} is even? ${ (item.attributables.NAME.length % 2) ? "false" : "true" }`);
        // return (item.attributables.NAME.length % 2) ? "true" : "false";
        return item.selezionato;
      };
    },
  },
  mounted() {},

  methods: {
    toLocaleCurrency,
    // override the default function defined in template
    // onSelectRow(value, item) {
    //   console.debug(`checked: ${value}, id: ${item.id}`)
    //   // TODO call backend and save the checked status of this item

    //   // then()... fetch data
    //   // this.fetch()
    // },
    rowClass(item, type) {
      if (!item || type !== "row") return;
      // return (item.registries.find(r => r.id == registry_id).pivot.primary === 'Y') ? 'table-success' : ''
      let status = item.status_registry.value;
      // let status = getAncillaryEffectiveStatus(date1, date2);
      return statusCSS[status + 1];
    },
    check(checked) {
      alert("check:" + checked);
      // console.debug(checked.insurer_id)
    },
    // info(item, index, button) {
    info(item, index, button) {
      // TODO: check if it's possible perform async calls here!!!
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      // this.$root.$emit("bv::show::modal", this.infoModal.id, button);
      this.$bvModal.show(this.infoModal.id, button);
    },
    onSelezionato(value, item) {
      this.$emit("selezionato", item);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
  },
};
</script>
<style lang="scss" scoped>
:deep(.check) {
  width: 1px;
  // padding-left: 10px;
}
:deep(.check .form-group) {
  margin-bottom: unset !important;
}
/* nelle table con form input, toglie il margine */
:deep(table .form-group) {
  margin-bottom: 0rem !important;
}
/** inserisce un gradiente orizzontale nella prima cella della table */
/* ROSA */
:deep(.effective-status-0 td:first-child) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 0, 0, 0.3) 100%
  );
}
/* ARANCIONE */
:deep(.effective-status-1 td:first-child) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(253, 173, 0, 0.4) 100%
  );
}
/* GIALLO */
:deep(.effective-status-2 td:first-child) {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 50%,
    rgba(255, 255, 0, 0.3) 100%
  );
}
/* NEUTRO */
:deep(.effective-status-3 td:first-child) {
  background: rgb(255, 255, 255);
}
/* :deep(.green td:first-child) {
  background: rgb(255,255,255);
  background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(0, 255, 85, 0.3) 100%);
} */
/* :deep(.white td:first-child) {
  background: rgb(255,255,255);
  background: linear-gradient(270deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgb(255, 255, 255) 100%);
} */
</style>
