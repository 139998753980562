<template>
  <validation-provider
    :mode="vmode"
    :vid="vid"
    :name="name"
    :rules="rules"
    v-slot="{ dirty, validated, valid, errors }"
  >
    <b-form-group
      :id="`input-group-${id}`"
      :label="label"
      :label-for="`input-${id}`"
      :class="{
        asterisk:
          Object.keys(rules).includes('required') ||
          (Object.keys(rules).includes('required_if') && !valid),
      }"
    >
      <b-form-checkbox-group
        :id="`input-${id}`"
        :name="groupName"
        v-model="inputVal"
        :options="options"
        :state="getValidationState({ dirty, validated, valid, errors })"
        :aria-describedby="`input-${id}-live-feedback`"
      ></b-form-checkbox-group>
      <b-form-invalid-feedback
        :id="`input-${id}-live-feedback`"
        class="d-block"
        >{{ errors[0] }}</b-form-invalid-feedback
      >
    </b-form-group>
  </validation-provider>
</template>
<script>
export default {
  props: {
    value: Array,
    vid: String,
    name: String,
    label: String,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    rules: {
      type: Object,
      default() {
        return {};
      },
    },
    vmode: {
      type: String,
      default: "aggressive",
    },
  },
  data() {
    return {
      id: null,
      groupName: `checkbox-${this._uid}`,
    };
  },
  mounted() {
    this.id = this._uid;
  },
  computed: {
    inputVal: {
      get() {
        // console.debug(`inputVal GET: ${JSON.stringify(this.value)}`);
        return this.value;
      },
      set(val) {
        // console.debug(`inputVal SET: ${JSON.stringify(val)}`);
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getValidationState({ dirty, validated, valid = null, errors = null }) {
      if (errors[0]) return false;
      return !Object.keys(this.rules).length
        ? null
        : dirty || validated
        ? valid
        : null;
    },
  },
};
</script>
